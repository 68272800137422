import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { signOutStoreUser } from './store/User/userAction'
import { useDispatch } from 'react-redux'

export function useAxiosInterceptor() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    axios.interceptors.response.use(response => response, error => {

        if (error.response && error.response.status === 403) {
            dispatch(signOutStoreUser())
            navigate('/')
        }
        return Promise.reject(error)

    })

    axios.interceptors.request.use((config) => {

        config.withCredentials = true
        return config

    })

}
