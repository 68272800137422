import Header from './components/Header'
import Nav from './components/Nav'
import Router from './Router'
import Footer from './components/Footer'
import { ToastContainer } from 'react-toastify'
import { useAxiosInterceptor } from './axiosInterceptor'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import './styles/colors.css'

function App() {

    useAxiosInterceptor()

    return (

        <div id={"App"}>
            <ToastContainer position='top-left' theme='light' />
            <Header />
            <Nav />
            <Router />
            <Footer />
        </div>

    )

}

export default App;
