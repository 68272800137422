import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Input/InputSearch.css'

function InputSearch({ type, dataDefault, setData, placeholder }) {

    const [search, setSearch] = useState("")

    function onChange(text) {

        if (type === 'user') {
            searchUser(text)
        } else if (type === 'company') {
            searchCompany(text)
        } else if (type === 'contact') {
            searchContact(text)
        } else if (type === 'project') {
            searchProject(text)
        }


    }

    function searchUser(text) {

        setSearch(text.toLowerCase())
        if (text.length > 0) {
            var dataFormat = []
            dataDefault.map((item) => {
                if (item.firstname.toLowerCase().search(text) !== -1 || item.lastname.toLowerCase().search(text) !== -1 ||
                    item.email.toLowerCase().search(text) !== -1 || item.role.toLowerCase().search(text) !== -1) {
                    dataFormat.push(item)
                }
            })
            setData(dataFormat)
        } else {
            setData(dataDefault)
        }

    }

    function searchCompany(text) {

        setSearch(text.toLowerCase())
        if (text.length > 0) {
            var dataFormat = []
            dataDefault.map((item) => {
                if (item.name.toLowerCase().search(text) !== -1) {
                    dataFormat.push(item)
                }
            })
            setData(dataFormat)
        } else {
            setData(dataDefault)
        }

    }

    function searchContact(text) {

        setSearch(text.toLowerCase())
        if (text.length > 0) {
            var dataFormat = []
            dataDefault.map((item, index) => {
                if (index === 0) {
                    dataFormat.push(item)
                } else {
                    if (item.firstname.toLowerCase().search(text) !== -1 || item.lastname.toLowerCase().search(text) !== -1 ||
                        item.email.toLowerCase().search(text) !== -1 || item.phone.toLowerCase().search(text) !== -1 ||
                        item.updatedAt.toLowerCase().search(text) !== -1 || item.createdAt.toLowerCase().search(text) !== -1) {
                        dataFormat.push(item)
                    }
                }
            })
            setData(dataFormat)
        } else {
            setData(dataDefault)
        }

    }

    function searchProject(text) {

        setSearch(text.toLowerCase())
        if (text.length > 0) {
            var dataFormat = []
            dataDefault.map((item) => {
                if (item.title.toLowerCase().search(text) !== -1 || item.description.toLowerCase().search(text) !== -1 ||
                    item.category.toLowerCase().search(text) !== -1 || item.status.toLowerCase().search(text) !== -1 ||
                    item.link.toLowerCase().search(text) !== -1 || item.updatedAt.toLowerCase().search(text) !== -1 ||
                    item.createdAt.toLowerCase().search(text) !== -1) {
                    dataFormat.push(item)
                }
            })
            setData(dataFormat)
        } else {
            setData(dataDefault)
        }

    }

    return (

        <InputGroup>
            <InputGroup.Text className='inputSearchIcon'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
                type={'text'}
                placeholder={placeholder}
                value={search}
                onChange={(e) => onChange(e.target.value)}
                className={'inputSearch'}
            />
            <InputGroup.Text className='inputSearchClear' onClick={() => { setSearch(""); setData(dataDefault) }}>
                <FontAwesomeIcon icon={faXmarkCircle} />
            </InputGroup.Text>
        </InputGroup>

    )

}

export default InputSearch
