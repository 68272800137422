import { Fade } from 'react-awesome-reveal'
import '../styles/CGU.css'

function CGU() {

    return (

        <div id="cgu" className='sectionLight'>
            <Fade direction={'up'}>
                <div className='sectionLightTitle'>Conditions générales d’utilisation du site Mitsab</div>
            </Fade>
            <br /><br />
            <Fade duration={2000}>
                <div>
                    <div className='CGUSubtitle'>Article 1 : Objet</div>
                    <div className='CGUText'>
                        Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site Mitsab (ci-après dénommé « le site »).
                        <br />
                        Constituant le contrat entre la société Mitsab, l’Utilisateur, l’accès au site doit être précédé de l’acceptation de ces CGU. L’accès à cette plateforme signifie l’acceptation des présentes CGU.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 2 : Mentions légales</div>
                    <div className='CGUText'>
                        L’édition du site Mitsab est assurée par la société Mitsab inscrite au RCS sous le numéro [Mitsab_numero_RCS], dont le siège social est localisé au [Mitsab_adresse_siege_social].
                        <br />
                        L’hébergeur du site Mitsab est la société OVH, sise au 2 rue Kellermann, 59100 Roubaix, France.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 3 : Accès au site</div>
                    <div className='CGUText'>
                        Le site Mitsab permet d’accéder gratuitement aux services suivants :
                        <ul>
                            <li>Information sur Mitsab et son fonctionnement</li>
                            <li>Prise de contact avec les collaborateurs de Mitsab</li>
                            <li>Demande de devis pour un projet professionel ou particulier</li>
                            <li>Accès à son compte client Mitsab pour retrouver toutes les informations sur les prestations éffectuées</li>
                        </ul>
                        Le site est accessible gratuitement depuis n’importe où pour tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…)
                        sont à la charge de l’utilisateur.
                        <br />
                        L’accès aux services dédiés aux membres s’effectue à l’aide d’un identifiant et d’un mot de passe.
                        <br />
                        Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 4 : Collecte des données</div>
                    <div className='CGUText'>
                        Pour la création du compte de l’Utilisateur ou la demande de contact, la collecte des informations sur le site est nécessaire et obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à l’informatique,
                        aux fichiers et aux libertés, la collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.
                        <br />
                        Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l’Utilisateur dispose du droit d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles.
                        <br />
                        L’exercice de ce droit s’effectue par le formulaire de contact
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 5 : Propriété intellectuelle</div>
                    <div className='CGUText'>
                        Les marques, logos ainsi que les contenus du site Mitsab (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.<br />
                        La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 6 : Responsabilité</div>
                    <div className='CGUText'>
                        Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.
                        <br />
                        Les informations diffusées sur le site Mitsab sont présentées à titre purement informatif et sont sans valeur contractuelle. En dépit des mises à jour régulières, la responsabilité du site ne
                        peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. Il en est de même pour l’utilisation et l’interprétation des
                        informations communiquées sur la plateforme.
                        <br />
                        Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de l’Utilisateur après l’utilisation ou l’accès à ce site.
                        <br />
                        Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.
                        <br />
                        La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site. Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 7 : Liens hypertextes</div>
                    <div className='CGUText'>
                        Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable
                        du contenu des pages web relatives à ces liens.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 8 : Cookies</div>
                    <div className='CGUText'>
                        Le site Mitsab n'utilise aucun cookie pour enregistrer des informations d'utilisateurs ou à des fins commercial.
                        <br />
                        Seul la connexion d'un utilisateur utilise un cookie pour garantir la fonctionnalité de connexion automatique sécurisé.
                        <br />
                        Lors de la connexion d'un utilisateur à son espace personnel : le site generera donc un cookie pour facilité la connexion automatique et sécurisé de l'utilisateur.
                        <br />
                        Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de l’Utilisateur. Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site.
                        Ces fichiers ne comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.
                        <br />
                        En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du logiciel de navigation.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 9 : Publication par l’Utilisateur</div>
                    <div className='CGUText'>
                        Dans ses publications, le membre est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en vigueur.
                        <br />
                        Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter,
                        reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un
                        tiers autorisé. Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.
                        <br />
                        L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes.
                        Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.
                        <br />
                        La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 10 : Durée du contrat</div>
                    <div className='CGUText'>
                        Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.
                    </div>
                </div>
                <div>
                    <div className='CGUSubtitle'>Article 11 : Droit applicable et juridiction compétente</div>
                    <div className='CGUText'>
                        Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.
                    </div>
                </div>
            </Fade>
        </div>

    )

}

export default CGU
