import { RotatingLines } from 'react-loader-spinner'
import { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserSecret, faUsers, faBuilding, faWindowMaximize, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Fade, Zoom } from 'react-awesome-reveal'
import axios from 'axios'
import AdminUserAdd from './AdminUserAdd'
import AdminUserUpdate from './AdminUserUpdate'
import AdminUserDelete from './AdminUserDelete'
import InputSearch from '../Input/InputSearch'
import '../../styles/Admin/AdminUser.css'
import '../../styles/utils/Accordion.css'
import AdminUserCompanyAdd from './AdminUserCompanyAdd'
import InputSwitchSelector from '../Input/InputSwitchSelector'
import AdminUserCompanyDelete from './AdminUserCompanyDelete'
import AdminUserCompanyUpdate from './AdminUserCompanyUpdate'
import { faWindows } from '@fortawesome/free-brands-svg-icons'

function AdminUser({ fullscreenDisplay, setFullscreenDisplay, users, setUsers, usersDefault, setUsersDefault }) {

    const [isLoading, setIsLoading] = useState(true)
    const [selectSwitch, setSelectSwitch] = useState("user")
    const [companies, setCompanies] = useState([])
    const [companiesDefault, setCompaniesDefault] = useState([])

    useEffect(() => {

        getUsers()
        getCompanies()

    }, [])

    function getUsers() {

        axios.get(window.location.protocol + '//api.' + window.location.host + '/' + "user").then((response) => {
            response.data.map((item) => {
                item.createdAt = new Date(item.createdAt).toLocaleString('fr-FR')
                item.updatedAt = new Date(item.updatedAt).toLocaleString('fr-FR')
                return item
            })
            setUsers(response.data)
            setUsersDefault(response.data)
            setIsLoading(false)
        }).catch((error) => {
            console.log("error :\n" + JSON.stringify(error) + "\n\n")
            setIsLoading(false)
        })

    }

    function getCompanies() {

        axios.get(window.location.protocol + '//api.' + window.location.host + '/' + "user/company").then((response) => {
            response.data.map((item) => {
                item.createdAt = new Date(item.createdAt).toLocaleString('fr-FR')
                item.updatedAt = new Date(item.updatedAt).toLocaleString('fr-FR')
                return item
            })
            setCompanies(response.data)
            setCompaniesDefault(response.data)
            setIsLoading(false)
        }).catch((error) => {
            console.log("error :\n" + JSON.stringify(error) + "\n\n")
            setIsLoading(false)
        })

    }

    return (

        <div id={'adminUserContainer'}>

            {fullscreenDisplay == 'user' ?
                <FontAwesomeIcon icon={faXmark} id='adminUserFullscreenIcon' onClick={() => setFullscreenDisplay(null)} />
                :
                <FontAwesomeIcon icon={faWindowMaximize} id='adminUserFullscreenIcon' onClick={() => setFullscreenDisplay('user')} />
            }
            <div id='adminUserTitle'>
                <FontAwesomeIcon icon={faUsers} id='adminUserTitleIcon' />
                Utilisateurs
            </div>
            <div id='adminUser'>
                {isLoading ?
                    <div id='adminUserLoader'>
                        <RotatingLines
                            strokeColor="#e2e9f0"
                            strokeWidth="5"
                            animationDuration="1"
                            width="40"
                            visible={true}
                        />
                    </div>
                    :
                    <>
                        <div id='adminUserRowButton'>
                            <AdminUserAdd
                                getUsers={getUsers}
                            />
                            <AdminUserCompanyAdd
                                getCompanies={getCompanies}
                            />
                        </div>
                        <InputSwitchSelector
                            data={[
                                { label: "Utilisateurs", value: "user" },
                                { label: "Entreprises", value: "company" }
                            ]}
                            value={selectSwitch}
                            setValue={(value) => setSelectSwitch(value)}
                            onChange={() => null}
                        />
                        <InputSearch
                            type={selectSwitch === 'user' ? 'user' : 'company'}
                            dataDefault={selectSwitch === 'user' ? usersDefault : companiesDefault}
                            setData={selectSwitch === 'user' ? setUsers : setCompanies}
                            placeholder={"Rechercher"}
                        />
                        <Zoom triggerOnce={true}>
                            {selectSwitch === 'user' ?
                                <Fade direction={'left'} triggerOnce={true} duration={500}>
                                    <Accordion>
                                        {users.map((item, index) => {
                                            return (
                                                <Accordion.Item key={index} eventKey={index}>
                                                    <Accordion.Header>
                                                        {item.role === 'user' ?
                                                            <FontAwesomeIcon icon={faUser} id='adminUserHeaderIcon' />
                                                            :
                                                            <FontAwesomeIcon icon={faUserSecret} id='adminUserHeaderIcon' />
                                                        }
                                                        {item.firstname} {item.lastname}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <AdminUserDelete
                                                            user={item}
                                                            getUsers={getUsers}
                                                        />
                                                        <AdminUserUpdate
                                                            user={item}
                                                            getUsers={getUsers}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })}
                                    </Accordion>
                                </Fade>
                                :
                                <div>
                                    <Fade direction={'right'} triggerOnce={true} duration={500}>
                                        <Accordion>
                                            {companies.map((item, index) => {
                                                return (
                                                    <Accordion.Item key={index} eventKey={index}>
                                                        <Accordion.Header>
                                                            <FontAwesomeIcon icon={faBuilding} id='adminUserHeaderIcon' />
                                                            {item.name}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <AdminUserCompanyDelete
                                                                company={item}
                                                                getCompanies={getCompanies}
                                                            />
                                                            <AdminUserCompanyUpdate
                                                                company={item}
                                                                getCompanies={getCompanies}
                                                                users={users}
                                                                getUsers={getUsers}
                                                            />
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })}
                                        </Accordion>
                                    </Fade>
                                </div>
                            }
                        </Zoom>
                    </>
                }
            </div>
        </div>

    )

}

export default AdminUser
