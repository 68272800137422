import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import imageHosting1 from '../../../assets/images/offer/offerHosting1.webp'
import imageHosting2 from '../../../assets/images/offer/offerHosting2.jpg'
import imageHosting3 from '../../../assets/images/offer/offerHosting3.jpg'
import iconPhp from '../../../assets/icons/techno/php.png'
import iconMySql from '../../../assets/icons/techno/mysql.png'
import iconNodeJS from '../../../assets/icons/techno/nodejs.png'
import iconDocker from '../../../assets/icons/techno/docker.png'
import iconLinux from '../../../assets/icons/techno/linux.png'
import iconWindows from '../../../assets/icons/techno/windows.png'
import '../../../styles/Home/Offer/Hosting.css'

function Hosting() {

    return (

        <div id="hosting" className='sectionLight'>
            <ParallaxProvider>

                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faServer} className='sectionLightTitleIcon' />
                            Hébergement
                        </div>
                    </Fade>
                    <div className='hostingSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='hostingSectionLightText'>
                                        <div className='hostingTextParagraph'>
                                            Chez nous, on comprend l'importance cruciale d'un hébergement fiable et performant pour votre site web et/ou
                                            votre application mobile.
                                        </div>
                                        <div className='hostingTextParagraph'>
                                            Nous sommes fiers de vous proposer nos services d'hébergement de premier ordre, conçus pour répondre à vos
                                            besoins spécifiques en matière d'hébergement web et mobile.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHosting1} width={'40%'} className='smartphoneImage' alt="imageHosting1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='hostingSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageHosting2} width={'70%'} className='smartphoneImage' alt="imageHosting2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='hostingSectionDarkText'>
                                            <div className='hostingTextParagraph'>
                                                Tous nos hébergements sont localisés sur des serveurs Français ; offrez-vous un environnement d'hébergement
                                                sécurisé pour votre site web, avec des certificats SSL (HTTPS) pour assurer la sécurité des données de vos utilisateurs.
                                            </div>
                                            <div className='hostingTextParagraph'>
                                                Notre infrastructure est optimisée pour garantir des temps de chargement rapides et une expérience utilisateur fluide.
                                            </div>
                                            <div className='hostingTextParagraph'>
                                                Notre équipe de support technique est disponible 7j/7 pour résoudre rapidement tout problème technique.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='hostingSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='hostingSectionLightText'>
                                        <div className='hostingTextParagraph'>
                                            Votre satisfaction est notre priorité absolue et nous travaillons rigoureusement pour vous offrir la meilleure
                                            expérience possible.
                                        </div>
                                        <div className='hostingTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière d'hébergement web et mobile, nous
                                            personnalisons nos services pour répondre à vos besoins.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHosting3} width={'60%'} className='smartphoneImage' alt="imageHosting3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='hostingIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconPhp} width="100px" alt="iconPhp" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='hostingIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faServer} className='sectionLightSmartphoneTitleIcon' />
                            Hébergement
                        </div>
                    </Fade>
                    <div className='hostingSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='hostingSmartphoneSectionLightText'>
                                        <div className='hostingTextParagraph'>
                                            Chez nous, on comprend l'importance cruciale d'un hébergement fiable et performant pour votre site web et/ou
                                            votre application mobile.
                                        </div>
                                        <div className='hostingTextParagraph'>
                                            Nous sommes fiers de vous proposer nos services d'hébergement de premier ordre, conçus pour répondre à vos
                                            besoins spécifiques en matière d'hébergement web et mobile.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHosting1} width={'90%'} className='hostingSmartphoneImage' alt="imageHosting1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='hostingSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageHosting2} width={'90%'} className='hostingSmartphoneImage' alt="imageHosting2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='hostingSmartphoneSectionDarkText'>
                                            <div className='hostingTextParagraph'>
                                                Tous nos hébergements sont localisés sur des serveurs Français ; offrez-vous un environnement d'hébergement
                                                sécurisé pour votre site web, avec des certificats SSL (HTTPS) pour assurer la sécurité des données de vos utilisateurs.
                                            </div>
                                            <div className='hostingTextParagraph'>
                                                Notre infrastructure est optimisée pour garantir des temps de chargement rapides et une expérience utilisateur fluide.
                                            </div>
                                            <div className='hostingTextParagraph'>
                                                Notre équipe de support technique est disponible 7j/7 pour résoudre rapidement tout problème technique.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='hostingSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='hostingSmartphoneSectionLightText'>
                                        <div className='hostingTextParagraph'>
                                            Votre satisfaction est notre priorité absolue et nous travaillons rigoureusement pour vous offrir la meilleure
                                            expérience possible.
                                        </div>
                                        <div className='hostingTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière d'hébergement web et mobile, nous
                                            personnalisons nos services pour répondre à vos besoins.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHosting3} width={'90%'} className='hostingSmartphoneImage' alt="imageHosting3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='hostingIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconPhp} width="100px" alt="iconPhp" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='hostingIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='hostingIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default Hosting
