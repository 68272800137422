import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { saveStoreUser, signOutStoreUser } from '../store/User/userAction'
import { HashLink } from 'react-router-hash-link'
import { BrowserView, MobileView } from 'react-device-detect'
import { sha512 } from 'js-sha512'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Fade, Zoom } from 'react-awesome-reveal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faHandshake, faUsers, faEnvelope, faSignIn, faKey, faSignOut, faGear } from '@fortawesome/free-solid-svg-icons'
import InputText from './Input/InputText'
import Button from './utils/Button'
import '../styles/Nav.css'

function Nav() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userStore = useSelector((state) => state.userReducer)
    const [displaySignIn, setDisplaySignIn] = useState(false)
    const [isSignIn, setIsSignIn] = useState(false)
    const [smartphoneMenuDisplay, setSmartphoneMenuDisplay] = useState(false)

    const emailRef = useRef()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const passwordRef = useRef()
    const [password, setPassword] = useState("test")
    const [passwordError, setPasswordError] = useState(null)

    const navLinks = [
        {
            title: 'Offres',
            icon: <FontAwesomeIcon icon={faHandshake} className='navLinkIcon' />,
            link: '/#offre'
        }, {
            title: 'Nous',
            icon: <FontAwesomeIcon icon={faUsers} className='navLinkIcon' />,
            link: '/#nous'
        }, {
            title: 'Contact',
            icon: <FontAwesomeIcon icon={faEnvelope} className='navLinkIcon' />,
            link: '/#contact'
        }
    ]

    useEffect(() => {

        // restoreEmail()
        // restoreSignIn()

        // eslint-disable-next-line
    }, [])

    // function restoreEmail() {

    //     if (userStore.email) {
    //         setEmail(userStore.email)
    //     }

    // }

    // function restoreSignIn() {

    //     if (userStore.tokenJWT) {
    //         setIsSignIn(true)
    //     } else {
    //         setIsSignIn(false)
    //     }

    // }

    // function signIn() {

        // var error = []
        // if (!emailRef.current.emailValidation(email)) {
        //     error.push("L'email est incorrect")
        // }
        // var passwordFormat = null
        // if (!passwordRef.current.passwordValidation(password)) {
        //     error.push("Le mot de passe est incorrect")
        // } else {
        //     passwordFormat = sha512(password).slice(5, 37)
        // }
        // if (error.length > 0) {
        //     error.map((item) => toast.error(item))
        // } else {
        //     const toastId = toast.loading("Connexion...")
        //     axios.post(window.location.protocol + '//api.' + window.location.host + '/' + "user/signIn", {
        //         email: email,
        //         password: passwordFormat
        //     }).then((response) => {
        //         if (response.data) {
        //             const tokenJWTDecoded = jwtDecode(response.data)
        //             dispatch(saveStoreUser({ id: tokenJWTDecoded.id, firstname: tokenJWTDecoded.firstname, email: email, tokenJWT: response.data }))
        //             toast.update(toastId, { render: "Connexion réussit", type: "success", isLoading: false, autoClose: 5000 })
        //             setDisplaySignIn(false)
        //             setIsSignIn(true)
        //             navigate('/administration')
        //         }
        //     }).catch((error) => {
        //         if (error.response) {
        //             console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
        //             var errorMessage = error.response.data.message
        //             if (errorMessage === 'USER_NOT_FOUND') {
        //                 errorMessage = "L'utilisateur est introuvable"
        //             } else if (errorMessage === 'ERROR_PASSWORD') {
        //                 errorMessage = "Le mot de passe est incorrect"
        //             } else if (errorMessage === 'ERROR_TOKENJWT') {
        //                 errorMessage = "Erreur lors de la création du token de connexion"
        //             }
        //             toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
        //         }
        //     })
        // }

    // }

    // function signOut() {

        // axios.get(window.location.protocol + '//api.' + window.location.host + '/' + 'user/signOut', {}, { withCredentials: true }).then(() => {
        //     dispatch(signOutStoreUser())
        //     setIsSignIn(false)
        //     navigate('/')
        // }).catch(() => {
        //     dispatch(signOutStoreUser())
        //     setIsSignIn(false)
        //     navigate('/')
        // })

    // }

    return (

        <nav id="nav">
            <Zoom>

                <BrowserView>
                    <Row>
                        <Col sm={3}>
                            <Fade direction={'left'}>
                                <div id='navLeft' onClick={() => window.scrollTo(0, 0)}>
                                    Mitsab
                                </div>
                            </Fade>
                        </Col>
                        <Col sm={6} id='navCenter'>
                            <div id='navLinks'>
                                {!displaySignIn ?
                                    <Fade direction={'left'}>
                                        {navLinks.map((item, index) => {
                                            return (
                                                <HashLink key={index} to={item.link} className='navLink'>
                                                    {item.icon}{item.title}
                                                </HashLink>
                                            )
                                        })}
                                        {isSignIn ?
                                            <HashLink to={'/administration'} className='navLink' onClick={() => window.scrollTo(0, 660)}>
                                                <FontAwesomeIcon icon={faGear} className='navLinkIcon' />
                                                Admin
                                            </HashLink>
                                            : null
                                        }
                                    </Fade>
                                    :
                                    <Fade direction={'right'}>
                                        <div id="navSignIn">
                                            <div id='navSignInForm'>
                                                <div className='navSignInInput'>
                                                    <InputText
                                                        type={'email'}
                                                        ref={emailRef}
                                                        text={email} setText={setEmail}
                                                        textError={emailError} setTextError={setEmailError}
                                                        icon={<FontAwesomeIcon icon={faEnvelope} />}
                                                        placeholder={"Email"}
                                                        maxLength={128}
                                                    />
                                                </div>
                                                <div className='navSignInInput'>
                                                    <InputText
                                                        type={'password'}
                                                        ref={passwordRef}
                                                        text={password} setText={setPassword}
                                                        textError={passwordError} setTextError={setPasswordError}
                                                        icon={<FontAwesomeIcon icon={faKey} />}
                                                        placeholder={"mdp"}
                                                    />
                                                </div>
                                                <div className='navSignInButton'>
                                                    <Button
                                                        type={'success'}
                                                        onClick={() => /*signIn()*/null}
                                                        icon={<FontAwesomeIcon icon={faSignIn} />}
                                                        text={'Connexion'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                }
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div id='navRight'>
                                {/* {!displaySignIn ?
                                    !isSignIn ?
                                        <Fade direction={'left'}>
                                            <div className='navLink' onClick={() => setDisplaySignIn(true)}>
                                                <FontAwesomeIcon icon={faSignIn} className='navLinkIcon' />
                                                Connexion
                                            </div>
                                        </Fade>
                                        :
                                        <Fade direction={'right'}>
                                            <div className='navLink' onClick={() => signOut()}>
                                                <FontAwesomeIcon icon={faSignOut} className='navLinkIcon' />
                                                Deconnexion
                                            </div>
                                        </Fade>
                                    :
                                    <Fade direction={'right'}>
                                        <FontAwesomeIcon icon={faXmark} className='navCloseIcon' onClick={() => setDisplaySignIn(false)} />
                                    </Fade>
                                } */}
                            </div>
                        </Col>
                    </Row>
                </BrowserView>

                <MobileView>
                    <div id="navSmartphoneBurger">

                        <div id='navSmartphoneBurgerTitle'>Mitsab</div>

                        <div className={smartphoneMenuDisplay ? "navSmartphoneBurglar is-open" : "navSmartphoneBurglar is-closed"} onClick={() => setSmartphoneMenuDisplay(!smartphoneMenuDisplay)}>
                            <div className="navSmartphoneBurgerIcon">
                                <div className="navSmartphoneBurgerIconContainer">
                                    <span className="navSmartphoneBurgerBunTop"></span>
                                    <span className="navSmartphoneBurgerFilling"></span>
                                    <span className="navSmartphoneBurgerBunBot"></span>
                                </div>
                            </div>
                            <div className="burger-ring">
                                <svg className="svg-ring">
                                    <path className="path" fill="none" stroke="#cddeec" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2" />
                                </svg>
                            </div>
                            <svg width="0" height="0">
                                <mask id="mask">
                                    <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ff0000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4" />
                                </mask>
                            </svg>
                            <div className="path-burger">
                                <div className="animate-path">
                                    <div className="path-rotation"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {smartphoneMenuDisplay ?
                        navLinks.map((item, index) => {
                            return (
                                <HashLink key={index} to={item.link} className='navSmartphoneLink' onClick={() => setSmartphoneMenuDisplay(false)}>
                                    <div className='navSmartphoneLinkText'>
                                        {item.icon}{item.title}
                                    </div>
                                </HashLink>
                            )
                        })
                        : null
                    }
                </MobileView>
            </Zoom>
        </nav>

    )

}

export default Nav
