import '../../styles/utils/Button.css'

function Button({ type, icon, text, onClick, onMouseOver, onMouseOut }) {

    function getStyle() {

        if (type === 'primary') {
            return 'buttonPrimary'
        } else if (type === 'secondary') {
            return 'buttonSecondary'
        } else if (type === 'success') {
            return 'buttonSuccess'
        } else if (type === 'warning') {
            return 'buttonWarning'
        } else if (type === 'danger') {
            return 'buttonDanger'
        }


    }

    return (

        <div className='buttonContainer'>
            <div
                className={getStyle()}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}>
                {icon} {text}
            </div>
        </div>

    )

}

export default Button
