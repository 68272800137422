import { useRef, useState } from 'react'
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEnvelope, faFloppyDisk, faGear, faUser, faUserGear, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import InputText from '../Input/InputText'
import InputSelect from '../Input/InputSelect'
import Button from '../utils/Button'
import '../../styles/Admin/AdminUserUpdate.css'
import { Col, Row } from 'react-bootstrap'

function AdminUserUpdate({ user, getUsers }) {

    const [isLoading, setIsLoading] = useState(false)
    const firstnameRef = useRef()
    const [firstname, setFirstname] = useState("")
    const [firstnameError, setFirstnameError] = useState(null)
    const lastnameRef = useRef(null)
    const [lastname, setLastname] = useState("")
    const [lastnameError, setLastnameError] = useState(null)
    const emailRef = useRef()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const typeRef = useRef()
    const [type, setType] = useState(null)
    const [typeError, setTypeError] = useState(null)
    const roleRef = useRef()
    const [role, setRole] = useState(null)
    const [roleError, setRoleError] = useState(null)

    const selectTypeData = user.type === 'person' ? [
        {
            value: 'person',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Particulier</>
        }, {
            value: 'company',
            label: <><FontAwesomeIcon icon={faBuilding} className='inputSelectLabelIcon' />Entreprise</>
        }
    ] : [
        {
            value: 'company',
            label: <><FontAwesomeIcon icon={faBuilding} className='inputSelectLabelIcon' />Entreprise</>
        }, {
            value: 'person',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Particulier</>
        }
    ]

    const selectTypeValue = user.type === 'person' ? {
        value: 'person',
        label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Particulier</>
    } : {
        value: 'company',
        label: <><FontAwesomeIcon icon={faBuilding} className='inputSelectLabelIcon' />Entreprise</>
    }

    const selectRoleData = user.type === 'person' ? [
        {
            value: 'user',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Utilisateur</>
        }, {
            value: 'admin',
            label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />Administrateur</>
        }
    ] : [
        {
            value: 'admin',
            label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />Administrateur</>
        }, {
            value: 'user',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Utilisateur</>
        }
    ]

    const selectRoleValue = user.type === 'person' ? {
        value: 'user',
        label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Utilisateur</>
    } : {
        value: 'admin',
        label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />Administrateur</>
    }



    function updateUser(userId) {

        setIsLoading(true)
        var error = []
        if (!firstname && !lastname && !email && !type && !role) {
            error.push("Aucun changement détecté")
        }
        var firstnameFormat = user.firstname
        if (firstname) {
            if (!firstnameRef.current.nameValidation(firstname)) {
                error.push("Le prénom est incorrect")
            }
            firstnameFormat = firstname
        }
        var lastnameFormat = user.lastname
        if (lastname) {
            if (!lastnameRef.current.nameValidation(lastname)) {
                error.push("Le nom est incorrect")
            }
            lastnameFormat = lastname
        }
        var emailFormat = user.email
        if (email) {
            if (!emailRef.current.emailValidation(email)) {
                error.push("L'email est incorrect")
            }
            emailFormat = email
        }
        var typeFormat = user.type
        if (type && type.value) {
            typeFormat = type.value
        }
        var roleFormat = user.role
        if (role && role.value) {
            roleFormat = role.value
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Modification...")
            axios.put(window.location.protocol + '//api.' + window.location.host + '/' + "user/" + userId, {
                firstname: firstnameFormat,
                lastname: lastnameFormat,
                email: emailFormat,
                type: typeFormat,
                role: roleFormat,
            }).then((response) => {
                if (response.data === 'USER_UPDATED') {
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setType(null)
                    setRole(null)
                    getUsers()
                    toast.update(toastId, { render: "Utilisateur modifié", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'EMAIL_ALREADY_EXIST') {
                        errorMessage = "L'email est déjà utilisé"
                    } else if (errorMessage === 'ERROR_USER_UPDATING') {
                        errorMessage = "Erreur lors de la modification de l'utilisateur"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    return (
        <>
            <div id='adminUserDate'>
                <Row>
                    <Col md='3' className='adminUserDateLeft'>
                        Créé le :
                    </Col>
                    <Col md='9' className='adminUserDateRight'>
                        {user.createdAt}
                    </Col>
                </Row>
                <Row>
                    <Col md='3' className='adminUserDateLeft'>
                        Modifié le :
                    </Col>
                    <Col md='9' className='adminUserDateRight'>
                        {user.updatedAt}
                    </Col>
                </Row>

            </div>
            <div id='adminUserUpdateForm'>
                <InputText
                    type={'name'}
                    ref={firstnameRef}
                    text={firstname} setText={setFirstname}
                    textError={firstnameError} setTextError={setFirstnameError}
                    icon={<FontAwesomeIcon icon={faUser} />}
                    nullable={true}
                    placeholder={user.firstname}
                    maxLength={32}
                />
                <InputText
                    type={'name'}
                    ref={lastnameRef}
                    text={lastname} setText={setLastname}
                    textError={lastnameError} setTextError={setLastnameError}
                    icon={<FontAwesomeIcon icon={faUser} />}
                    nullable={true}
                    placeholder={user.lastname}
                    maxLength={32}
                />
                <InputText
                    type={'email'}
                    ref={emailRef}
                    text={email} setText={setEmail}
                    textError={emailError} setTextError={setEmailError}
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                    nullable={true}
                    placeholder={user.email}
                    maxLength={128}
                />
                <InputSelect
                    ref={typeRef}
                    data={selectTypeData}
                    select={type} setSelect={setType}
                    selectError={typeError} setSelectError={setTypeError}
                    icon={<FontAwesomeIcon icon={faUserGear} />}
                    value={selectTypeValue}
                    multiple={false}
                />
                <InputSelect
                    ref={roleRef}
                    data={selectRoleData}
                    select={role} setSelect={setRole}
                    selectError={roleError} setSelectError={setRoleError}
                    icon={<FontAwesomeIcon icon={faGear} />}
                    value={selectRoleValue}
                    multiple={false}
                />
                {isLoading ?
                    <RotatingLines
                        strokeColor="#e2e9f0"
                        strokeWidth="5"
                        animationDuration="1"
                        width="40"
                        visible={true}
                    />
                    :
                    <Button
                        type={'success'}
                        onClick={() => updateUser(user.id)}
                        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                        text={'Modifier'}
                    />
                }
            </div>
        </>
    )

}

export default AdminUserUpdate
