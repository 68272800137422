import { Modal as ModalBootstrap } from 'react-bootstrap'
import Button from './Button'
import '../../styles/utils/Modal.css'

function Modal({ displayModal, setDisplayModal, buttonDisplayType, buttonDisplayIcon, buttonDisplayText, header, body, footer }) {

    return (

        <>

            <Button type={buttonDisplayType} size='small' icon={buttonDisplayIcon} text={buttonDisplayText} onClick={() => setDisplayModal(true)} />
            <ModalBootstrap show={displayModal} size="lg" onHide={() => setDisplayModal(false)}>
                <ModalBootstrap.Header closeButton={true}>
                    <ModalBootstrap.Title>{header}</ModalBootstrap.Title>
                </ModalBootstrap.Header>
                <ModalBootstrap.Body>
                    {body}
                </ModalBootstrap.Body>
                <ModalBootstrap.Footer>
                    {footer}
                </ModalBootstrap.Footer>
            </ModalBootstrap>
        </>

    )

}

export default Modal