import React, { forwardRef, useImperativeHandle } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Input/InputTextarea.css'

const InputTextarea = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({ textValidation: (text) => textValidation(text) }))

    function textValidation(text) {

        if (text && text.length > 0) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    return (

        <InputGroup>
            <InputGroup.Text className={props.textError == null ? 'inputTextareaIcon' : !props.textError ? 'inputTextareaIconSuccess' : 'inputTextareaIconError'}>
                {props.icon ? props.icon : <FontAwesomeIcon icon={faPen} />}
            </InputGroup.Text>
            <Form.Control
                as={'textarea'}
                placeholder={props.placeholder}
                value={props.text}
                onChange={(e) => { props.setText(e.target.value); textValidation(e.target.value) }}
                className={props.textError == null ? 'inputTextarea' : !props.textError ? 'inputTextareaSuccess' : 'inputTextareaError'}
                rows={props.nbRow ? props.nbRow : 10}
            />
        </InputGroup>

    )

})

export default InputTextarea
