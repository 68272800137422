import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDisplay } from '@fortawesome/free-solid-svg-icons'
import imageWebsite1 from '../../../assets/images/offer/offerWebsite1.jpg'
import imageWebsite2 from '../../../assets/images/offer/offerWebsite2.jpg'
import imageWebsite3 from '../../../assets/images/offer/offerWebsite3.jpg'
import iconHtml from '../../../assets/icons/techno/html.png'
import iconCss from '../../../assets/icons/techno/css.png'
import iconJs from '../../../assets/icons/techno/js.png'
import iconPhp from '../../../assets/icons/techno/php.png'
import iconMySql from '../../../assets/icons/techno/mysql.png'
import iconNodeJS from '../../../assets/icons/techno/nodejs.png'
import iconReactJS from '../../../assets/icons/techno/reactjs.png'
import iconNestJS from '../../../assets/icons/techno/nestjs.png'
import iconDocker from '../../../assets/icons/techno/docker.png'
import '../../../styles/Home/Offer/Website.css'

function Website() {

    return (

        <div id="website" className='sectionLight'>
            <ParallaxProvider>
                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faDisplay} className='sectionLightTitleIcon' />
                            Site Web
                        </div>
                    </Fade>
                    <div className='websiteSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='websiteSectionLightText'>
                                        <div className='websiteTextParagraph'>
                                            Vous avez besoin d'une présence en ligne, d'un simple site vitrine personnelle ou d'une plateforme web plus complexe pour développer votre activité professionnelle ?
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nos ingénieurs sont là pour répondre à votre demande, notre équipe est composée de professionnels qualifiés et expérimentés dans la conception et le développement web.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nous établissons au préalable avec le client une charte graphique, sur mesure, en harmonie avec vos valeurs afin de créer une identité visuelle qui vous correspond.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageWebsite1} width={'70%'} className='websiteImage' alt="imageWebsite1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='websiteSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageWebsite2} width={'60%'} className='websiteImage' alt="imageWebsite2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='websiteSectionDarkText'>
                                            <div className='websiteTextParagraph'>
                                                Nous travaillons en méthode agile avec une grande flexibilité pour pouvoir modifier le site web tout au long du développement selon vos envies.
                                            </div>
                                            <div className='websiteTextParagraph'>
                                                Notre équipe de développement web est experte dans la technologie ReactJS, Framework qui permettent une
                                                réalisation rapide, optimisée et sécurisée de votre site web.
                                            </div>
                                            <div className='websiteTextParagraph'>
                                                Nous respectons la vie privée de chaque personne et nous sommes en accord avec les normes RGPD et autres établies par la CNIL.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='websiteSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='websiteSectionLightText'>
                                        <div className='websiteTextParagraph'>
                                            Nous vous formerons sur la gestion de votre site web et réalisons avec vous des tests sur toutes les fonctionnalités attendues.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nos tarifs sont compétitifs et nous offrons une transparence totale en ce qui concerne les coûts.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Vous pouvez demander votre devis gratuitement en nous expliquant le projet souhaité dès maintenant.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageWebsite3} width={'70%'} className='websiteImage' alt="imageWebsite1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconHtml} width="70px" alt="iconHtml" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconCss} width="50px" alt="iconCss" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconJs} width="55px" alt="iconJs" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconPhp} width="100px" alt="iconPhp" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconReactJS} width="100px" alt="iconReactJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNestJS} width="120px" alt="iconNestJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faDisplay} className='sectionLightSmartphoneTitleIcon' />
                            Site Web
                        </div>
                    </Fade>
                    <div className='websiteSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='websiteSmartphoneSectionLightText'>
                                        <div className='websiteTextParagraph'>
                                            Vous avez besoin d'une présence en ligne, d'un simple site vitrine personnelle ou d'une plateforme web plus complexe pour développer votre activité professionnelle ?
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nos ingénieurs sont là pour répondre à votre demande, notre équipe est composée de professionnels qualifiés et expérimentés dans la conception et le développement web.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nous établissons au préalable avec le client une charte graphique, sur mesure, en harmonie avec vos valeurs afin de créer une identité visuelle qui vous correspond.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageWebsite1} width={'90%'} className='websiteImageSmartphone' alt="imageWebsite1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='websiteSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageWebsite2} width={'90%'} className='websiteImageSmartphone' alt="imageWebsite2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='websiteSmartphoneSectionDarkText'>
                                            <div className='websiteTextParagraph'>
                                                Nous travaillons en méthode agile avec une grande flexibilité pour pouvoir modifier le site web tout au long du développement selon vos envies.
                                            </div>
                                            <div className='websiteTextParagraph'>
                                                Notre équipe de développement web est experte dans la technologie ReactJS, Framework qui permettent une
                                                réalisation rapide, optimisée et sécurisée de votre site web.
                                            </div>
                                            <div className='websiteTextParagraph'>
                                                Nous respectons la vie privée de chaque personne et nous sommes en accord avec les normes RGPD et autres établies par la CNIL.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='websiteSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='websiteSmartphoneSectionLightText'>
                                        <div className='websiteTextParagraph'>
                                            Nous vous formerons sur la gestion de votre site web et réalisons avec vous des tests sur toutes les fonctionnalités attendues.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Nos tarifs sont compétitifs et nous offrons une transparence totale en ce qui concerne les coûts.
                                        </div>
                                        <div className='websiteTextParagraph'>
                                            Vous pouvez demander votre devis gratuitement en nous expliquant le projet souhaité dès maintenant.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageWebsite3} width={'90%'} className='websiteImageSmartphone' alt="imageWebsite1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconHtml} width="70px" alt="iconHtml" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconCss} width="50px" alt="iconCss" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconJs} width="55px" alt="iconJs" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconPhp} width="100px" alt="iconPhp" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconReactJS} width="100px" alt="iconReactJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNestJS} width="120px" alt="iconNestJS" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='websiteIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default Website
