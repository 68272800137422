import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import { ReactTyped } from 'react-typed'
import '../styles/Header.css'

function Header() {

    return (

        <header>

            <BrowserView>
                <div id="header">
                    <Fade direction={'down'} triggerOnce={true}>
                        <div id="headerText">
                            <div id="headerTextTitle">
                                Mitsab
                                {/* <ReactTyped
                                    strings={[""]}
                                    startDelay={300}
                                    typeSpeed={60}
                                    showCursor={false}
                                /> */}
                            </div>
                            <p id="headerTextSubtitle">
                                <ReactTyped
                                    strings={["Solutions digitales pour les entreprises et particuliers"]}
                                    startDelay={600}
                                    typeSpeed={10}
                                    showCursor={false}
                                />
                            </p>
                        </div>
                    </Fade>
                </div>
            </BrowserView>

            <MobileView>
                <div id="header">
                    <div id="headerText">
                        <div id="headerTextTitleSmartphone">

                            <ReactTyped
                                strings={["mitsab"]}
                                startDelay={300}
                                typeSpeed={60}
                                showCursor={false}
                            />
                        </div>
                        <p id="headerTextSubtitle">
                            <ReactTyped
                                strings={["Solutions digitales pour les entreprises et particuliers"]}
                                startDelay={1000}
                                typeSpeed={10}
                                showCursor={false}
                            />
                        </p>
                    </div>
                </div>
            </MobileView>

        </header>
        // </Fade >

    )

}

export default Header
