import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTerminal } from '@fortawesome/free-solid-svg-icons'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import imageDevOps1 from '../../../assets/images/offer/offerDevops1.webp'
import imageDevOps2 from '../../../assets/images/offer/offerDevops2.png'
import imageDevOps3 from '../../../assets/images/offer/offerDevops3.png'
import iconNodeJS from '../../../assets/icons/techno/nodejs.png'
import iconTerraform from '../../../assets/icons/techno/terraform.png'
import iconAnsible from '../../../assets/icons/techno/ansible.png'
import iconDocker from '../../../assets/icons/techno/docker.png'
import iconKubernetes from '../../../assets/icons/techno/kubernetes.png'
import iconLinux from '../../../assets/icons/techno/linux.png'
import iconWindows from '../../../assets/icons/techno/windows.png'
import '../../../styles/Home/Offer/DevOps.css'

function DevOps() {

    return (

        <div id="devOps" className='sectionLight'>
            <ParallaxProvider>

                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faTerminal} className='sectionLightTitleIcon' />
                            DevOps
                        </div>
                    </Fade>
                    <div className='devOpsSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='devOpsSectionLightText'>
                                        <div className='devOpsTextParagraph'>
                                            Nos équipes DevOps sont là pour améliorer l'efficacité, la stabilité et la rapidité de déploiement de vos applications.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Chez Mitsab, nous comprenons l'importance de la collaboration transparente entre les équipes de
                                            développement et d'exploitation pour assurer le succès continu de vos projets.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Mise en place de pipelines CI/CD pour automatiser les tests, la compilation et le déploiement.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageDevOps1} width={'60%'} className='smartphoneImage' alt="imageDevOps1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='devOpsSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageDevOps2} width={'60%'} className='smartphoneImage' alt="imageDevOps2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='devOpsSectionDarkText'>
                                            <div className='devOpsTextParagraph'>
                                                Mise en place de solutions de surveillance en temps réel pour identifier et résoudre rapidement les problèmes.
                                            </div>
                                            <div className='devOpsTextParagraph'>
                                                Utilisation de technologies avancées de logging pour une analyse approfondie des performances et
                                                intégration de pratiques de sécurité dès le début du cycle de développement.
                                            </div>
                                            <div className='devOpsTextParagraph'>
                                                Mise en place de politiques de sécurité robustes pour protéger les données et les systèmes.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='devOpsSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='devOpsSectionLightText'>
                                        <div className='devOpsTextParagraph'>
                                            Une équipe d'experts qualifiés avec une expérience approfondie dans la mise en œuvre réussie de solutions DevOps.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Personnalisation des services en fonction de vos besoins spécifiques et de la nature de vos projets.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour discuter de la manière dont nos services DevOps peuvent optimiser vos
                                            opérations et accélérer le développement de vos projets.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageDevOps3} width={'60%'} className='smartphoneImage' alt="imageDevOps3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconTerraform} width="60px" alt="iconTerraform" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconAnsible} width="60px" alt="iconAnsible" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconKubernetes} width="60px" alt="iconKubernetes" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faTerminal} className='sectionLightSmartphoneTitleIcon' />
                            DevOps
                        </div>
                    </Fade>
                    <div className='devOpsSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='devOpsSmartphoneSectionLightText'>
                                        <div className='devOpsTextParagraph'>
                                            Nos équipes DevOps sont là pour améliorer l'efficacité, la stabilité et la rapidité de déploiement de vos applications.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Chez Mitsab, nous comprenons l'importance de la collaboration transparente entre les équipes de
                                            développement et d'exploitation pour assurer le succès continu de vos projets.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Mise en place de pipelines CI/CD pour automatiser les tests, la compilation et le déploiement.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageDevOps1} width={'90%'} className='devOpsSmartphoneImage' alt="imageDevOps1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='devOpsSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageDevOps2} width={'90%'} className='devOpsSmartphoneImage' alt="imageDevOps2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='devOpsSmartphoneSectionDarkText'>
                                            <div className='devOpsTextParagraph'>
                                                Mise en place de solutions de surveillance en temps réel pour identifier et résoudre rapidement les problèmes.
                                            </div>
                                            <div className='devOpsTextParagraph'>
                                                Utilisation de technologies avancées de logging pour une analyse approfondie des performances et
                                                intégration de pratiques de sécurité dès le début du cycle de développement.
                                            </div>
                                            <div className='devOpsTextParagraph'>
                                                Mise en place de politiques de sécurité robustes pour protéger les données et les systèmes.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='devOpsSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='devOpsSmartphoneSectionLightText'>
                                        <div className='devOpsTextParagraph'>
                                            Une équipe d'experts qualifiés avec une expérience approfondie dans la mise en œuvre réussie de solutions DevOps.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Personnalisation des services en fonction de vos besoins spécifiques et de la nature de vos projets.
                                        </div>
                                        <div className='devOpsTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour discuter de la manière dont nos services DevOps peuvent optimiser vos
                                            opérations et accélérer le développement de vos projets.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageDevOps3} width={'90%'} className='devOpsSmartphoneImage' alt="imageDevOps3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconTerraform} width="60px" alt="iconTerraform" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconAnsible} width="60px" alt="iconAnsible" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconKubernetes} width="60px" alt="iconKubernetes" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='devOpsIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default DevOps
