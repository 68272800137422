import Offer from './Offer/Offer'
import Us from './Us'
import Contact from './Contact'
import Vision from './Vision'

function Home() {

    return (

        <>
            <Vision />
            <Offer />
            <Us />
            <Contact />
        </>

    )

}

export default Home
