import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import '../styles/Footer.css'
import { Fade } from 'react-awesome-reveal'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Footer() {

    const socialNetwork = [
        {
            icon: <FontAwesomeIcon icon={faLinkedinIn} id='footerLinkedinIcon' />,
            link: "https://fr.linkedin.com/"
        }, {
            icon: <FontAwesomeIcon icon={faFacebookF} id='footerFacebookIcon' />,
            link: "https://www.facebook.com/?locale=fr_FR"
        },
    ]

    return (

        <div id="footer">
            <Row>
                <Col id='footerLeft'>
                    <Fade direction={'left'}>
                        <Link className='footerLink' to={'/cgu'} onClick={() => window.scrollTo({ top: 660, behavior: 'instant' })}>
                            <FontAwesomeIcon icon={faFileContract} className='footerLinkIcon' />
                            CGU
                        </Link>
                    </Fade>
                </Col>
                <Col id='footerRight'>
                    <Fade direction={'right'}>
                        {socialNetwork.map((item, index) => {
                            return (
                                <a key={index} href={item.link} target="_blank" rel="noreferrer">{item.icon}</a>
                            )
                        })}
                    </Fade>

                </Col>
            </Row>

        </div>

    )

}

export default Footer
