import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faFloppyDisk, faTrash, faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import Avatar from 'react-avatar'
import Modal from '../utils/Modal'
import InputText from '../Input/InputText'
import Button from '../utils/Button'
import InputSelect from '../Input/InputSelect'
import '../../styles/Admin/AdminUserCompanyUpdate.css'
import AdminUserCompanyDelete from './AdminUserCompanyDelete'

function AdminUserCompanyUpdate({ company, getCompanies, users, getUsers }) {

    const [isLoading, setIsLoading] = useState(false)
    const [avatarPopoverDisplay, setAvatarPopoverDisplay] = useState(null)
    const [employeeDeleteDisplay, setEmployeeDeleteDisplay] = useState(false)
    const nameRef = useRef()
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
    const employeesRef = useRef()
    const [employees, setEmployees] = useState(null)
    const [employeesData, setEmployeesData] = useState(null)
    const [employeesError, setEmployeesError] = useState(null)

    useEffect(() => {

        formatUsers()

    }, [])

    function formatUsers(companyEmployeesId) {

        var companyEmployeesFormat = []
        users.map((item) => {
            companyEmployeesFormat.push(item)
        })
        if (companyEmployeesId) {
            if (companyEmployeesId.length > 0) {
                users.map((item) => {
                    const employeeId = companyEmployeesId.find((itemEmployeeId) => itemEmployeeId == item.id)
                    const employee = item
                    if (employeeId) {
                        companyEmployeesFormat.splice(companyEmployeesFormat.indexOf(employee), 1)
                    }
                })
            }
        } else {
            users.map((item) => {
                const employeeId = company.employees.find((itemEmployee) => itemEmployee.id == item.id)
                const employee = item
                if (employeeId) {
                    companyEmployeesFormat.splice(companyEmployeesFormat.indexOf(employee), 1)
                }
            })
        }
        var formatUsers = []
        companyEmployeesFormat.map((item) => {
            var employeeItem = null
            if (item.role === 'user') {
                employeeItem = {
                    value: item.id,
                    label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />{item.firstname + " " + item.lastname}</>
                }
            } else {
                employeeItem = {
                    value: item.id,
                    label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />{item.firstname + " " + item.lastname}</>
                }
            }
            formatUsers.push(employeeItem)
        })
        setEmployeesData(formatUsers)

    }

    function updateUserCompany(companyId) {

        setIsLoading(true)
        var error = []
        if (!name) {
            error.push("Aucun changement détecté")
        }
        var nameFormat = company.name
        if (name) {
            if (!nameRef.current.nameValidation(name)) {
                error.push("Le nom est incorrect")
            }
            nameFormat = name
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Modification...")
            axios.put(window.location.protocol + '//api.' + window.location.host + '/' + "user/company/" + companyId, {
                name: nameFormat,
            }).then((response) => {
                if (response.data === 'USERCOMPANY_UPDATED') {
                    setName("")
                    getCompanies()
                    toast.update(toastId, { render: "Enterprise modifié", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'USERCOMPANY_ALREADY_EXIST') {
                        errorMessage = "L'entreprise existe déjà"
                    } else if (errorMessage === 'ERROR_USERCOMPANY_UPDATING') {
                        errorMessage = "Erreur lors de la modification de l'entreprise"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    function addUserCompanyEmployee(companyId) {

        setIsLoading(true)
        var error = []
        if (!employeesRef.current.selectValidation(employees)) {
            error.push("Les employées sont incorrects")
        }
        var employeesFormat = []
        if (employees.length > 0) {
            company.employees.map((item) => {
                employeesFormat.push(parseInt(item.id))
            })
            employees.map((item) => {
                employeesFormat.push(parseInt(item.value))
            })
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Modification...")
            axios.put(window.location.protocol + '//api.' + window.location.host + '/' + "user/company/" + companyId + "/employee", {
                employeesId: employeesFormat,
            }).then((response) => {
                if (response.data === 'USERCOMPANY_UPDATED') {
                    setEmployees(null)
                    getCompanies()
                    formatUsers(employeesFormat)
                    toast.update(toastId, { render: "Employées ajoutés", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'USERCOMPANY_NOT_FOUND') {
                        errorMessage = "L'entreprise est introuvable"
                    } else if (errorMessage === 'EMPLOYEE_NOT_FOUND') {
                        errorMessage = "L'employé est introuvable"
                    } else if (errorMessage === 'ERROR_USERCOMPANY_UPDATING') {
                        errorMessage = "Erreur lors de l'ajout de l'employé"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    function deleteUserCompanyEmployee(companyEmployeeId) {

        setIsLoading(true)
        var employeesFormat = []
        company.employees.map((item) => {
            if (item.id != companyEmployeeId) {
                employeesFormat.push(parseInt(item.id))
            }
        })
        const toastId = toast.loading("Modification...")
        axios.put(window.location.protocol + '//api.' + window.location.host + '/' + "user/company/" + company.id + "/employee", {
            employeesId: employeesFormat,
        }).then((response) => {
            if (response.data === 'USERCOMPANY_UPDATED') {
                getUsers()
                getCompanies()
                setAvatarPopoverDisplay(null)
                setEmployeeDeleteDisplay(false)
                formatUsers(employeesFormat)
                toast.update(toastId, { render: "Employées retiré", type: "success", isLoading: false, autoClose: 5000 })
            }
            setIsLoading(false)
        }).catch((error) => {
            if (error.response) {
                console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                var errorMessage = error.response.data.message
                if (errorMessage === 'USERCOMPANY_NOT_FOUND') {
                    errorMessage = "L'entreprise est introuvable"
                } else if (errorMessage === 'EMPLOYEE_NOT_FOUND') {
                    errorMessage = "L'employé est introuvable"
                } else if (errorMessage === 'ERROR_USERCOMPANY_UPDATING') {
                    errorMessage = "Erreur lors de l'ajout de l'employé"
                }
                toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
            }
            setIsLoading(false)
        })

    }

    return (
        <>
            <div id='adminUserCompanyDate'>
                <Row>
                    <Col md='3' className='adminUserCompanyDateLeft'>
                        Créé le :
                    </Col>
                    <Col md='9' className='adminUserCompanyDateRight'>
                        {company.createdAt}
                    </Col>
                </Row>
                <Row>
                    <Col md='3' className='adminUserCompanyDateLeft'>
                        Modifié le :
                    </Col>
                    <Col md='9' className='adminUserCompanyDateRight'>
                        {company.updatedAt}
                    </Col>
                </Row>
            </div>
            <div id='adminUserCompanyDate'>
                <InputText
                    type={'name'}
                    ref={nameRef}
                    text={name} setText={setName}
                    textError={nameError} setTextError={setNameError}
                    icon={<FontAwesomeIcon icon={faBuilding} />}
                    nullable={true}
                    placeholder={company.name}
                    maxLength={32}
                />
                {isLoading ?
                    <RotatingLines
                        strokeColor="#e2e9f0"
                        strokeWidth="5"
                        animationDuration="1"
                        width="40"
                        visible={true}
                    />
                    :
                    <Button
                        type={'success'}
                        onClick={() => updateUserCompany(company.id)}
                        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                        text={'Modifier'}
                    />
                }
            </div>
            <div id='adminUserCompanyEmployee'>
                Employée
                <div>

                    {company.employees.map((item, index) => {
                        return (
                            <OverlayTrigger
                                trigger={"click"}
                                show={avatarPopoverDisplay == index ? true : false}
                                key={index}
                                placement={'top'}
                                overlay={
                                    <Popover>
                                        <Popover.Header as="h3">{item.firstname + " " + item.lastname}</Popover.Header>
                                        <Popover.Body>
                                            <Modal
                                                displayModal={employeeDeleteDisplay}
                                                setDisplayModal={setEmployeeDeleteDisplay}
                                                buttonDisplayType={'danger'}
                                                buttonDisplayIcon={<FontAwesomeIcon icon={faTrash} />}
                                                buttonDisplayText={"Retirer"}
                                                header={"Supprimer " + item.firstname + " " + item.lastname}
                                                body={
                                                    <>
                                                        Êtes vous sur de retirer l'employée :
                                                        <br /><br />
                                                        firstname : {item.firstname}
                                                        <br />
                                                        lastname : {item.lastname}
                                                        <br />
                                                    </>
                                                }
                                                footer={
                                                    <>
                                                        {isLoading ?
                                                            <RotatingLines
                                                                strokeColor="#e2e9f0"
                                                                strokeWidth="5"
                                                                animationDuration="1"
                                                                width="40"
                                                                visible={true}
                                                            />
                                                            :
                                                            <Button
                                                                type={'danger'}
                                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                                                text={'Retirer'}
                                                                onClick={() => deleteUserCompanyEmployee(item.id)}
                                                            />
                                                        }
                                                    </>
                                                }
                                            />
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <Avatar
                                    className='adminUserCompanyEmployeeAvatar'
                                    name={item.firstname + " " + item.lastname}
                                    size={'50'}
                                    round={true}
                                    onClick={() => avatarPopoverDisplay != index ? setAvatarPopoverDisplay(index) : setAvatarPopoverDisplay(null)}
                                />
                            </OverlayTrigger>
                        )
                    })}


                </div>
                <div id='adminUserCompanyEmployeeForm'>
                    <InputSelect
                        ref={employeesRef}
                        data={employeesData}
                        select={employees} setSelect={setEmployees}
                        selectError={employeesError} setSelectError={setEmployeesError}
                        icon={<FontAwesomeIcon icon={faUser} />}
                        value={employees}
                        placeholder={"Utilisateur"}
                        multiple={true}
                    />
                    {isLoading ?
                        <RotatingLines
                            strokeColor="#e2e9f0"
                            strokeWidth="5"
                            animationDuration="1"
                            width="40"
                            visible={true}
                        />
                        :
                        <Button
                            type={'success'}
                            onClick={() => addUserCompanyEmployee(company.id)}
                            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                            text={'Ajouter'}
                        />
                    }
                </div>
            </div>
        </>
    )

}

export default AdminUserCompanyUpdate
