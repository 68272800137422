import { useState } from 'react'
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../utils/Button'
import Modal from '../utils/Modal'
import '../../styles/Admin/AdminUserCompanyDelete.css'

function AdminUserCompanyDelete({ company, getCompanies }) {

    const [isLoading, setIsLoading] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)

    function deleteUserCompany(companyId) {

        setIsLoading(true)
        const toastId = toast.loading("Suppression...")
        axios.delete(window.location.protocol + '//api.' + window.location.host + '/' + "user/company/" + companyId).then((response) => {
            if (response.data === 'USERCOMPANY_DELETED') {
                getCompanies()
                setDisplayModal(false)
                toast.update(toastId, { render: "Entreprise supprimé", type: "success", isLoading: false, autoClose: 5000 })
            }
            setIsLoading(false)
        }).catch((error) => {
            if (error.response) {
                console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                var errorMessage = error.response.data.message
                if (errorMessage === 'USERCOMPANY_NOT_FOUND') {
                    errorMessage = "L'entreprise est introuvable"
                } else if (errorMessage === 'ERROR_USERCOMPANY_DELETING') {
                    errorMessage = "Erreur lors de la suppression de l'entreprise"
                }
                toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
            }
            setIsLoading(false)
        })

    }

    return (

        <div id='adminUserDelete'>
            <Modal
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                buttonDisplayType={'danger'}
                buttonDisplayIcon={<FontAwesomeIcon icon={faTrash} />}
                buttonDisplayText={"Supprimer"}
                header={"Supprimer " + company.name}
                body={
                    <>
                        Êtes vous sur de supprimer l'entreprise :
                        <br /><br />
                        Nom : {company.name}
                        <br />
                    </>
                }
                footer={
                    <>
                        {isLoading ?
                            <RotatingLines
                                strokeColor="#e2e9f0"
                                strokeWidth="5"
                                animationDuration="1"
                                width="40"
                                visible={true}
                            />
                            :
                            <Button
                                type={'danger'}
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                text={'Supprimer'}
                                onClick={() => deleteUserCompany(company.id)}
                            />
                        }
                    </>
                }
            />
        </div>
    )

}

export default AdminUserCompanyDelete
