import SwitchSelector from 'react-switch-selector'
import '../../styles/Input/InputSwitchSelector.css'

function InputSwitchSelector({ data, setValue, onChange }) {

    return (

        <div className='switchSelectorContainer'>
            <div className='switchSelector'>
                <SwitchSelector
                    onChange={(value) => {setValue(value); onChange(value)}}
                    options={data}
                    backgroundColor={'#242f41'}
                    selectedBackgroundColor={'#3b4c68'}
                    fontColor={'#ffffff'}
                    fontSize={14}
                />
            </div>
        </div>

    )

}

export default InputSwitchSelector
