import { useRef, useState } from 'react'
import axios from 'axios'
import { sha512 } from 'js-sha512'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEnvelope, faGear, faKey, faPlus, faUser, faUserGear, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import Modal from '../utils/Modal'
import InputText from '../Input/InputText'
import InputSelect from '../Input/InputSelect'
import Button from '../utils/Button'
import '../../styles/Admin/AdminUserAdd.css'

function AdminUserAdd({ getUsers }) {

    const [isLoading, setIsLoading] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const firstnameRef = useRef()
    const [firstname, setFirstname] = useState("")
    const [firstnameError, setFirstnameError] = useState(null)
    const lastnameRef = useRef(null)
    const [lastname, setLastname] = useState("")
    const [lastnameError, setLastnameError] = useState(null)
    const emailRef = useRef()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const passwordRef = useRef()
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(null)
    const typeRef = useRef()
    const [type, setType] = useState(null)
    const [typeError, setTypeError] = useState(null)
    const roleRef = useRef()
    const [role, setRole] = useState(null)
    const [roleError, setRoleError] = useState(null)

    const selectTypeData = [
        {
            value: 'person',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Particulier</>
        }, {
            value: 'company',
            label: <><FontAwesomeIcon icon={faBuilding} className='inputSelectLabelIcon' />Entreprise</>
        }
    ]

    const selectRoleData = [
        {
            value: 'user',
            label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Utilisateur</>
        }, {
            value: 'admin',
            label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />Administrateur</>
        }
    ]

    function addUser() {

        setIsLoading(true)
        var error = []
        if (!firstnameRef.current.nameValidation(firstname)) {
            error.push("Le prénom est incorrect")
        }
        if (!lastnameRef.current.nameValidation(lastname)) {
            error.push("Le nom est incorrect")
        }
        if (!emailRef.current.emailValidation(email)) {
            error.push("L'email est incorrect")
        }
        var passwordFormat = null
        if (!passwordRef.current.passwordValidation(password)) {
            error.push("Le mot de passe est incorrect")
        } else {
            passwordFormat = sha512(password).slice(5, 37)
        }
        if (!typeRef.current.selectValidation(type)) {
            error.push("Le type est incorrect")
        }
        if (!roleRef.current.selectValidation(role)) {
            error.push("Le rôle est incorrect")
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Ajout...")
            axios.post(window.location.protocol + '//api.' + window.location.host + '/' + "user", {
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: passwordFormat,
                type: type.value,
                role: role.value
            }).then((response) => {
                if (response.data === 'USER_CREATED') {
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setType(null)
                    setRole(null)
                    getUsers()
                    setDisplayModal(false)
                    toast.update(toastId, { render: "Utilisateur ajouté", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'EMAIL_ALREADY_EXIST') {
                        errorMessage = "L'email est déjà utilisé"
                    } else if (errorMessage === 'ERROR_USER_CREATION') {
                        errorMessage = "Erreur lors de l'ajout de l'utilisateur"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    return (

        <div id="adminUserAdd">
            <Modal
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                buttonDisplayType={'secondary'}
                buttonDisplayIcon={<FontAwesomeIcon icon={faPlus} />}
                buttonDisplayText={"Utilisateur"}
                header={"Ajouter un utilisateur"}
                body={
                    <div id="adminUserAddForm">
                        <InputText
                            type={'name'}
                            ref={firstnameRef}
                            text={firstname} setText={setFirstname}
                            textError={firstnameError} setTextError={setFirstnameError}
                            icon={<FontAwesomeIcon icon={faUser} />}
                            placeholder={"Prénom"}
                            maxLength={32}
                        />
                        <InputText
                            type={'name'}
                            ref={lastnameRef}
                            text={lastname} setText={setLastname}
                            textError={lastnameError} setTextError={setLastnameError}
                            icon={<FontAwesomeIcon icon={faUser} />}
                            placeholder={"Nom"}
                            maxLength={32}
                        />
                        <InputText
                            type={'email'}
                            ref={emailRef}
                            text={email} setText={setEmail}
                            textError={emailError} setTextError={setEmailError}
                            icon={<FontAwesomeIcon icon={faEnvelope} />}
                            placeholder={"Email"}
                            maxLength={128}
                        />
                        <InputText
                            type={'password'}
                            ref={passwordRef}
                            text={password} setText={setPassword}
                            textError={passwordError} setTextError={setPasswordError}
                            icon={<FontAwesomeIcon icon={faKey} />}
                            placeholder={"mdp"}
                            passwordButtonVisible={true}
                            passwordButtonGenerate={true}
                        />
                        <InputSelect
                            ref={typeRef}
                            data={selectTypeData}
                            select={type} setSelect={setType}
                            selectError={typeError} setSelectError={setTypeError}
                            icon={<FontAwesomeIcon icon={faUserGear} />}
                            value={type}
                            placeholder={"Type"}
                            multiple={false}
                        />
                        <InputSelect
                            ref={roleRef}
                            data={selectRoleData}
                            select={role} setSelect={setRole}
                            selectError={roleError} setSelectError={setRoleError}
                            icon={<FontAwesomeIcon icon={faGear} />}
                            value={role}
                            placeholder={"Rôle"}
                            multiple={false}
                        />
                    </div>
                }
                footer={
                    <>
                        {isLoading ?
                            <RotatingLines
                                strokeColor="#e2e9f0"
                                strokeWidth="5"
                                animationDuration="1"
                                width="40"
                                visible={true}
                            />
                            :
                            <Button
                                type={'success'}
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                text={'Ajouter'}
                                onClick={() => addUser()}
                            />
                        }
                    </>
                }
            />
        </div>

    )

}

export default AdminUserAdd
