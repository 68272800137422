import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faHandPointUp, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Fade, Zoom } from 'react-awesome-reveal'
import '../../styles/Home/Vision.css'

function Vision() {

    return (

        <div id="vision" className='sectionLight'>
            <BrowserView>
                <Fade direction={'up'}>
                    <div className='sectionLightTitle'>
                        L'informatique pour tous
                    </div>
                </Fade>
                <div id='visionCards'>
                    <Zoom>
                        <div className='visionCard'>
                            <FontAwesomeIcon icon={faEye} className='visionCardIcon' />
                            <div className='visionCardTitle'>Notre Vision</div>
                            <div className='visionCardText'>Accompagner les entreprises et les particuliers dans leurs projets</div>
                        </div>
                    </Zoom>
                    <Zoom delay={300}>
                        <div className='visionCard'>
                            <FontAwesomeIcon icon={faHandPointUp} className='visionCardIcon' />
                            <div className='visionCardTitle'>Nos Promesses</div>
                            <div className='visionCardText'>S'assurer de votre satisfaction, continuer de faire vivre vos projets</div>
                        </div>
                    </Zoom>
                    <Zoom delay={600}>
                        <div className='visionCardRight'>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='visionCardIcon' />
                            <div className='visionCardTitle'>Notre Expertise</div>
                            <div className='visionCardText'>Tous nos ingénieurs sont spécialisés dans leur domaine informatique</div>
                        </div>
                    </Zoom>
                </div>
            </BrowserView>

            <MobileView>
                <Fade direction={'up'}>
                    <div className='sectionLightSmartphoneTitle'>
                        L'informatique pour tous
                    </div>
                </Fade>
                <Zoom>
                    <div className='visionSmartphoneCard'>
                        <FontAwesomeIcon icon={faEye} className='visionCardIcon' />
                        <div className='visionCardTitle'>Notre Vision</div>
                        <div className='visionCardText'>Accompagner les entreprises et les particuliers dans leurs projets</div>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='visionSmartphoneCard'>
                        <FontAwesomeIcon icon={faHandPointUp} className='visionCardIcon' />
                        <div className='visionCardTitle'>Nos Promesses</div>
                        <div className='visionCardText'>S'assurer de votre satisfaction, continuer de faire vivre vos projets</div>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='visionSmartphoneCard'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='visionCardIcon' />
                        <div className='visionCardTitle'>Notre Expertise</div>
                        <div className='visionCardText'>Tous nos ingénieurs sont spécialisés dans leur domaine informatique</div>
                    </div>
                </Zoom>
            </MobileView>
        </div>

    )

}

export default Vision
