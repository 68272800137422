import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDisplay, faVideo } from '@fortawesome/free-solid-svg-icons'
import imageVideo1 from '../../../assets/images/offer/offerVideo1.jpg'
import imageVideo2 from '../../../assets/images/offer/offerVideo2.webp'
import imageVideo3 from '../../../assets/images/offer/offerVideo3.jpg'
import '../../../styles/Home/Offer/Video.css'

function Video() {

    return (

        <div id="video" className='sectionLight'>
            <ParallaxProvider>

                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faVideo} className='sectionLightTitleIcon' />
                            Montage Vidéo
                        </div>
                    </Fade>
                    <div className='videoSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='videoSectionLightText'>
                                        <div className='videoTextParagraph'>
                                            Nous avons le plaisir de vous présenter notre offre de services de montage vidéo pour répondre à vos besoins de communication visuelle et de marketing.
                                        </div>
                                        <div className='videoTextParagraph'>
                                            En tant que professionnels du montage vidéo, nous sommes déterminés à offrir des solutions créatives et de haute qualité pour valoriser votre image. Nous assurons un montage vidéo
                                            professionnel en utilisant des logiciels de pointe pour garantir un rendu visuel exceptionnel.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageVideo1} width={'70%'} className='videoImage' alt="imageVideo1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='videoSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageVideo2} width={'60%'} className='videoImage' alt="imageVideo2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='videoSectionDarkText'>
                                            <div className='videoTextParagraph'>
                                                Chaque projet est unique, nous personnalisons nos services pour répondre à vos besoins spécifiques et à l'identité de votre entreprise.
                                            </div>
                                            <div className='videoTextParagraph'>
                                                Nous travaillons sur une variété de formats vidéo, y compris des vidéos promotionnelles, des tutoriels, des vidéos institutionnelles, et bien plus encore.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='videoSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='videoSectionLightText'>
                                        <div className='videoTextParagraph'>
                                            Nous proposons des forfaits adaptés à différents budgets, avec la possibilité de personnaliser les services en fonction de vos besoins spécifiques.
                                        </div>
                                        <div className='videoTextParagraph'>
                                            Nous serions ravis de discuter de vos projets de montage vidéo et de trouver la solution qui correspond le mieux à vos attentes.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageVideo3} width={'70%'} className='videoImage' alt="imageVideo1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faDisplay} className='sectionLightSmartphoneTitleIcon' />
                            Site Web
                        </div>
                    </Fade>
                    <div className='videoSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='videoSmartphoneSectionLightText'>
                                        <div className='videoTextParagraph'>
                                            Nous avons le plaisir de vous présenter notre offre de services de montage vidéo pour répondre à vos besoins de communication visuelle et de marketing.
                                        </div>
                                        <div className='videoTextParagraph'>
                                            En tant que professionnels du montage vidéo, nous sommes déterminés à offrir des solutions créatives et de haute qualité pour valoriser votre image. Nous assurons un montage vidéo
                                            professionnel en utilisant des logiciels de pointe pour garantir un rendu visuel exceptionnel.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageVideo1} width={'90%'} className='videoSmartphoneImage' alt="imageVideo1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='videoSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageVideo2} width={'90%'} className='videoSmartphoneImage' alt="imageVideo2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='videoSmartphoneSectionDarkText'>
                                            <div className='videoTextParagraph'>
                                                Chaque projet est unique, nous personnalisons nos services pour répondre à vos besoins spécifiques et à l'identité de votre entreprise.
                                            </div>
                                            <div className='videoTextParagraph'>
                                                Nous travaillons sur une variété de formats vidéo, y compris des vidéos promotionnelles, des tutoriels, des vidéos institutionnelles, et bien plus encore.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='videoSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='videoSmartphoneSectionLightText'>
                                        <div className='videoTextParagraph'>
                                            Nous proposons des forfaits adaptés à différents budgets, avec la possibilité de personnaliser les services en fonction de vos besoins spécifiques.
                                        </div>
                                        <div className='videoTextParagraph'>
                                            Nous serions ravis de discuter de vos projets de montage vidéo et de trouver la solution qui correspond le mieux à vos attentes.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageVideo3} width={'90%'} className='videoSmartphoneImage' alt="imageVideo1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default Video
