import { useEffect, useRef, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import publicIp from 'react-public-ip'
import { toast } from 'react-toastify'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane, faPen, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { RotatingLines } from 'react-loader-spinner'
import { Fade } from 'react-awesome-reveal'
import InputText from '../Input/InputText'
import InputTextarea from '../Input/InputTextarea'
import Button from '../utils/Button'
import '../../styles/Home/Contact.css'
import axios from 'axios'

function Contact() {

    const [isLoading, setIsLoading] = useState(false)
    const [displaySuccess, setDisplaySuccess] = useState(false)
    const [ip, setIp] = useState(null)

    const firstnameRef = useRef()
    const [firstname, setFirstname] = useState("")
    const [firstnameError, setFirstnameError] = useState(null)
    const lastnameRef = useRef(null)
    const [lastname, setLastname] = useState("")
    const [lastnameError, setLastnameError] = useState(null)
    const emailRef = useRef()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const phoneRef = useRef()
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(null)
    const messageRef = useRef()
    const [message, setMessage] = useState("")
    const [messageError, setMessageError] = useState(null)

    useEffect(() => {

        getIp()

    }, [])

    async function getIp() {

        const ipv4 = await publicIp.v4() || ""
        const ipv6 = await publicIp.v6() || ""
        if (ipv4) {
            setIp(ipv4)
        } else if (ipv6) {
            setIp(ipv6)
        }

    }

    function sendMessage() {

        setIsLoading(true)
        var error = []
        if (!lastnameRef.current.nameValidation(lastname)) {
            error.push("Le nom est incorrect")
        }
        if (!firstnameRef.current.nameValidation(firstname)) {
            error.push("Le prénom est incorrect")
        }
        if (!emailRef.current.emailValidation(email)) {
            error.push("L'email est incorrect")
        }
        if (!phoneRef.current.phoneValidation(phone)) {
            error.push("Le téléphone est incorrect")
        }
        if (!messageRef.current.textValidation(message)) {
            error.push("Le message est incorrect")
        }
        if (!ip) {
            error.push("L'IP est introuvable")
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Envois...")
            axios.post(window.location.protocol + '//api.' + window.location.host + '/' + "contact", {
                lastname: lastname,
                firstname: firstname,
                email: email,
                phone: phone,
                message: message,
                ip: ip,
            }).then((response) => {
                console.log("reponse\n\n")
                if (response.data === 'CONTACT_CREATED') {
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setPhone("")
                    setMessage("")
                    setDisplaySuccess(true)
                    toast.update(toastId, { render: "Message envoyé", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'ERROR_CONTACT_CREATION') {
                        errorMessage = "Erreur lors de l'envois du message"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    return (

        <div id="contact" className='sectionDark'>
            <BrowserView>
                <div className='sectionDarkTitle'>
                    <FontAwesomeIcon icon={faEnvelope} className='sectionDarkTitleIcon' />
                    Nous contacter
                </div>
                <div id='contactForm'>
                    {displaySuccess ?
                        <Fade>
                            <div id='contactFormSuccess'>
                                Le message à été envoyé
                            </div>
                        </Fade>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Fade direction={'left'}>
                                        <InputText
                                            type={'name'}
                                            ref={firstnameRef}
                                            text={firstname} setText={setFirstname}
                                            textError={firstnameError} setTextError={setFirstnameError}
                                            icon={<FontAwesomeIcon icon={faUser} />}
                                            placeholder={"Prénom"}
                                            maxLength={32}
                                        />
                                    </Fade>
                                </Col>
                                <Col>
                                    <Fade direction={'right'}>
                                        <InputText
                                            type={'name'}
                                            ref={lastnameRef}
                                            text={lastname} setText={setLastname}
                                            textError={lastnameError} setTextError={setLastnameError}
                                            icon={<FontAwesomeIcon icon={faUser} />}
                                            placeholder={"Nom"}
                                            maxLength={32}
                                        />
                                    </Fade>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Fade direction={'left'} delay={300}>
                                        <InputText
                                            type={'email'}
                                            ref={emailRef}
                                            text={email} setText={setEmail}
                                            textError={emailError} setTextError={setEmailError}
                                            icon={<FontAwesomeIcon icon={faEnvelope} />}
                                            placeholder={"Email"}
                                            maxLength={128}
                                        />
                                    </Fade>
                                </Col>
                                <Col>
                                    <Fade direction={'right'} delay={300}>
                                        <InputText
                                            type={'phone'}
                                            ref={phoneRef}
                                            text={phone} setText={setPhone}
                                            textError={phoneError} setTextError={setPhoneError}
                                            icon={<FontAwesomeIcon icon={faPhone} />}
                                            placeholder={"Phone"}
                                            maxLength={14}
                                        />
                                    </Fade>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Fade direction={'up'}>
                                        <InputTextarea
                                            type={'textarea'}
                                            ref={messageRef}
                                            text={message} setText={setMessage}
                                            textError={messageError} setTextError={setMessageError}
                                            icon={<FontAwesomeIcon icon={faPen} />}
                                            placeholder={"Message"}
                                            nbRow={6}
                                        />
                                    </Fade>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {!isLoading ?
                                        <Fade direction={'up'}>
                                            <Button
                                                type={'success'}
                                                onClick={() => sendMessage()}
                                                icon={<FontAwesomeIcon icon={faPaperPlane} />}
                                                text={'Envoyer'}
                                            />
                                        </Fade>
                                        :
                                        <Fade>
                                            <br />
                                            <RotatingLines
                                                strokeColor={"#e2e9f0"}
                                                strokeWidth={"5"}
                                                animationDuration={"1"}
                                                width={"40"}
                                                visible={true}
                                            />
                                        </Fade>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </BrowserView>

            <MobileView>
                <div className='sectionDarkTitle'>
                    <FontAwesomeIcon icon={faEnvelope} className='sectionDarkTitleIcon' />
                    Nous contacter
                </div>
                <div id='contactSmartphoneForm'>
                    {displaySuccess ?
                        <Fade>
                            <div id='contactFormSuccess'>
                                Le message à été envoyé
                            </div>
                        </Fade>
                        :
                        <>
                            <Fade direction={'left'}>
                                <InputText
                                    type={'name'}
                                    ref={firstnameRef}
                                    text={firstname} setText={setFirstname}
                                    textError={firstnameError} setTextError={setFirstnameError}
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    placeholder={"Prénom"}
                                    maxLength={32}
                                />
                            </Fade>
                            <Fade direction={'right'}>
                                <InputText
                                    type={'name'}
                                    ref={lastnameRef}
                                    text={lastname} setText={setLastname}
                                    textError={lastnameError} setTextError={setLastnameError}
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    placeholder={"Nom"}
                                    maxLength={32}
                                />
                            </Fade>
                            <Fade direction={'left'} delay={300}>
                                <InputText
                                    type={'email'}
                                    ref={emailRef}
                                    text={email} setText={setEmail}
                                    textError={emailError} setTextError={setEmailError}
                                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                                    placeholder={"Email"}
                                    maxLength={128}
                                />
                            </Fade>
                            <Fade direction={'right'} delay={300}>
                                <InputText
                                    type={'phone'}
                                    ref={phoneRef}
                                    text={phone} setText={setPhone}
                                    textError={phoneError} setTextError={setPhoneError}
                                    icon={<FontAwesomeIcon icon={faPhone} />}
                                    placeholder={"Phone"}
                                    maxLength={14}
                                />
                            </Fade>
                            <Fade direction={'up'}>
                                <InputTextarea
                                    type={'textarea'}
                                    ref={messageRef}
                                    text={message} setText={setMessage}
                                    textError={messageError} setTextError={setMessageError}
                                    icon={<FontAwesomeIcon icon={faPen} />}
                                    placeholder={"Message"}
                                    nbRow={6}
                                />
                            </Fade>
                            {!isLoading ?
                                <Fade direction={'up'}>
                                    <Button
                                        type={'success'}
                                        onClick={() => sendMessage()}
                                        icon={<FontAwesomeIcon icon={faPaperPlane} />}
                                        text={'Envoyer'}
                                    />
                                </Fade>
                                :
                                <Fade>
                                    <br />
                                    <RotatingLines
                                        strokeColor={"#e2e9f0"}
                                        strokeWidth={"5"}
                                        animationDuration={"1"}
                                        width={"40"}
                                        visible={true}
                                    />
                                </Fade>
                            }
                        </>
                    }
                </div>
            </MobileView>
        </div >

    )

}

export default Contact
