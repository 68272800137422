import { useNavigate } from 'react-router-dom'
import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDisplay, faHandshake, faHouseChimney, faMobileScreen, faServer, faTerminal, faVideo } from '@fortawesome/free-solid-svg-icons'
import { Fade } from 'react-awesome-reveal'
import { Col, Row, Badge } from 'react-bootstrap'
import '../../../styles/Home/Offer/Offer.css'

function Offer() {

    const navigate = useNavigate()

    const offers = [
        {
            left: {
                title: "Site Web",
                titleIcon: <FontAwesomeIcon icon={faDisplay} className='offersTitleIcon' />,
                text: <>Conception et création sur mesure<br />Site statique ou dynamique<br />Hergement sur des serveurs français<br />Maintenance et sécurité 7j/7</>,
                BadgeText: "À partir de 999€",
                link: "/site-web",
                backgroundId: "offerWebsite"
            },
            right: {
                title: "Application Mobile",
                titleIcon: <FontAwesomeIcon icon={faMobileScreen} className='offersTitleIcon' />,
                text: <>Application mobile IOS, Android<br />API sécurisé<br />Licence pour l'AppStore et le PlayStore<br />Maintenance et sécurité 7j/7</>,
                BadgeText: "À partir de 1899€",
                link: "/application-mobile",
                backgroundId: "offerApp"
            }
        }, {
            left: {
                title: "Hébergement",
                titleIcon: <FontAwesomeIcon icon={faServer} className='offersTitleIcon' />,
                text: <>Herbergement dédié et adapté<br />Nom de domaine<br />Certificats SSL (HTTPS)<br />Maintenance et sécurité 7j/7</>,
                BadgeText: "À partir de 49€/mois",
                link: "/hebergement",
                backgroundId: "offerHosting"
            },
            right: {
                title: "Domotique",
                titleIcon: <FontAwesomeIcon icon={faHouseChimney} className='offersTitleIcon' />,
                text: <>Déploiement Home Assistant<br />Automatiser votre maison<br />Interface personnalisée<br />Maintenance et sécurité 7j/7</>,
                BadgeText: "À partir de 699€",
                link: "/domotique",
                backgroundId: "offerHome"
            }
        }, {
            left: {
                title: "DevOps",
                titleIcon: <FontAwesomeIcon icon={faTerminal} className='offersTitleIcon' />,
                text: <>Déploiement logiciel<br />Administration système<br />Outils interne d'entreprise<br />Maintenance et sécurité 7j/7</>,
                BadgeText: "À partir de 1699€",
                link: "/devops",
                backgroundId: "offerDevops"
            },
            right: {
                title: "Montage vidéo",
                titleIcon: <FontAwesomeIcon icon={faVideo} className='offersTitleIcon' />,
                text: <><br />Un rendu professionnel<br />Une personnalisation sur mesure<br />Choisissez le format que vous voulez</>,
                BadgeText: "À partir de 399€",
                link: "/video",
                backgroundId: "offerVideo"
            }
        }
    ]

    return (

        <div id="offre" className='sectionDark'>

            <BrowserView>
                <Fade direction={'up'}>
                    <div className='sectionDarkTitle'>
                        <FontAwesomeIcon icon={faHandshake} className='sectionDarkTitleIcon' />
                        Offres de services
                    </div>
                </Fade>
                {offers.map((item, index) => {
                    return (
                        <Row key={index} className='offersRow'>
                            <Col>
                                <Fade direction={'left'}>
                                    {item.left ?
                                        <div id={item.left.backgroundId} className='offersColLeft' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.left.link) }}>
                                            <div className="offersColBadge">
                                                <Badge bg="primary" pill={true}>{item.left.BadgeText}</Badge>
                                            </div>
                                            <div className='offersTitle'>
                                                {item.left.titleIcon}{item.left.title}
                                            </div>
                                            <div className='offersText'>
                                                {item.left.text}
                                            </div>
                                        </div>
                                        : null
                                    }
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    {item.right ?
                                        <div id={item.right.backgroundId} className='offersColRight' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.right.link) }}>
                                            <div className="offersColBadge">
                                                <Badge bg="primary" pill={true}>{item.right.BadgeText}</Badge>
                                            </div>
                                            <div className='offersTitle'>
                                                {item.right.titleIcon}{item.right.title}
                                            </div>
                                            <div className='offersText'>
                                                {item.right.text}
                                            </div>
                                        </div>
                                        : null
                                    }
                                </Fade>
                            </Col>
                        </Row>
                    )
                })}
            </BrowserView>



            <MobileView>
                <Fade direction={'up'}>
                    <div className='sectionDarkSmartphoneTitle'>
                        <FontAwesomeIcon icon={faHandshake} className='sectionDarkSmartphoneTitleIcon' />
                        Offres de services
                    </div>
                </Fade>
                {offers.map((item, index) => {
                    return (
                        <div key={index}>
                            <Fade direction={'left'}>
                                {item.left ?
                                    <div id={item.left.backgroundId} className='offersSmartphoneCol' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.left.link) }}>
                                        <div className="offersSmartphoneColBadge">
                                            <Badge bg="primary" pill={true}>{item.left.BadgeText}</Badge>
                                        </div>
                                        <div className='offersTitle'>
                                            {item.left.titleIcon}{item.left.title}
                                        </div>
                                        <div className='offersSmartphoneText'>
                                            {item.left.text}
                                        </div>
                                    </div>
                                    : null
                                }
                            </Fade>
                            <Fade direction={'right'}>
                                {item.right ?
                                    <div id={item.right.backgroundId} className='offersSmartphoneCol' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.right.link) }}>
                                        <div className="offersSmartphoneColBadge">
                                            <Badge bg="primary" pill={true}>{item.right.BadgeText}</Badge>
                                        </div>
                                        <div className='offersTitle'>
                                            {item.right.titleIcon}{item.right.title}
                                        </div>
                                        <div className='offersSmartphoneText'>
                                            {item.right.text}
                                        </div>
                                    </div>
                                    : null
                                }
                            </Fade>
                        </div>
                    )
                })}
            </MobileView>


            {/* <MobileView>
                <Fade direction={'up'}>
                    <div className='sectionDarkSmartphoneTitle'>
                        <FontAwesomeIcon icon={faHandshake} className='sectionDarkSmartphoneTitleIcon' />
                        Offres de services
                    </div>
                </Fade>
                {offers.map((item, index) => {
                    return (
                        <div key={index}>
                            <Fade direction={'left'}>
                                {item.left ?
                                    <div className='offersSmartphoneCol' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.left.link) }}>
                                        <div className="offersSmartphoneColBadge">
                                            <Badge bg="primary" pill={true}>{item.left.BadgeText}</Badge>
                                        </div>
                                        <div className='offersTitle'>
                                            {item.left.titleIcon}{item.left.title}
                                        </div>
                                        <div className='offersSmartphoneText'>
                                            {item.left.text}
                                        </div>
                                    </div>
                                    : null
                                }
                            </Fade>
                            <Fade direction={'right'}>
                                {item.right ?
                                    <div className='offersSmartphoneCol' onClick={() => { window.scrollTo({ top: 660, behavior: 'instant' }); navigate(item.right.link) }}>
                                        <div className="offersSmartphoneColBadge">
                                            <Badge bg="primary" pill={true}>{item.right.BadgeText}</Badge>
                                        </div>
                                        <div className='offersTitle'>
                                            {item.right.titleIcon}{item.right.title}
                                        </div>
                                        <div className='offersSmartphoneText'>
                                            {item.right.text}
                                        </div>
                                    </div>
                                    : null
                                }
                            </Fade>
                        </div>
                    )
                })}
            </MobileView> */}
        </div>

    )

}

export default Offer
