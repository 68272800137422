import { Route, Routes } from 'react-router-dom'
import Home from './components/Home/Home'
import Website from './components/Home/Offer/Website'
import Smartphone from './components/Home/Offer/Smartphone'
import Hosting from './components/Home/Offer/Hosting'
import HomeAutomation from './components/Home/Offer/HomeAutomation'
import DevOps from './components/Home/Offer/DevOps'
import CGU from './components/CGU'
import Admin from './components/Admin/Admin'
import Video from './components/Home/Offer/Video'

function Router() {

    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/site-web" element={<Website />} />
            <Route path="/application-mobile" element={<Smartphone />} />
            <Route path="/hebergement" element={<Hosting />} />
            <Route path="/domotique" element={<HomeAutomation />} />
            <Route path="/devops" element={<DevOps />} />
            <Route path="/video" element={<Video />} />
            <Route path="/cgu" element={<CGU />} />
            <Route path="/administration" element={<Admin />} />
        </Routes>

    )

}

export default Router
