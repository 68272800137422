import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faShuffle, faT } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Input/InputText.css'
import { sha512 } from 'js-sha512'

const InputText = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        textValidation: (text) => textValidation(text),
        nameValidation: (name) => nameValidation(name),
        emailValidation: (email) => emailValidation(email),
        phoneValidation: (phone) => phoneValidation(phone),
        passwordValidation: (password) => passwordValidation(password),
    }))

    const [passwordVisible, setPasswordVisible] = useState(false)

    function onChange(text) {

        if (props.type === 'text') {
            textValidation(text)
        } else if (props.type === 'name') {
            nameFormat(text)
            nameValidation(text)
        } else if (props.type === 'email') {
            emailFormat(text)
            emailValidation(text)
        } else if (props.type === 'phone') {
            phoneValidation(text)
        } else if (props.type === 'password') {
            passwordValidation(text)
        }

    }

    function onBlur(text) {

        if (props.type === 'phone' && !props.textError) {
            phoneFormat(text)
        }

    }

    function nameFormat(name) {

        if (name) {
            var nameFormat = name.toLowerCase().split(' ').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
            nameFormat = nameFormat.split('-').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('-')
            nameFormat = nameFormat.split('\'').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('\'')
            props.setText(nameFormat)
        }

    }

    function nameValidation(name) {

        const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        if (props.nullable && name.length === 0) {
            props.setTextError(null)
            return true
        }
        if (name && nameRegex.test(name)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function emailFormat(email) {

        if (email) {
            props.setText(email.toLowerCase().trim())
        }

    }

    function emailValidation(email) {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (props.nullable && email.length === 0) {
            props.setTextError(null)
            return true
        }
        if (email && emailRegex.test(email)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }


    }

    function phoneValidation(phone) {

        const phoneRegex = /^[0-9 ]+$/
        if (props.nullable && phone.length === 0) {
            props.setTextError(null)
            return true
        }
        if (phone && (phone.length === 10 || phone.length === 14) && phoneRegex.test(phone)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function phoneFormat(phone) {

        if (phone.length === 10) {
            props.setText(phone[0] + phone[1] + " " + phone[2] + phone[3] + " " + phone[4] + phone[5] + " " + phone[6] + phone[7] + " " + phone[8] + phone[9])
        }

    }

    function getRandomPassword() {

        const date = new Date().toString()
        const passwordGenerate = sha512(date).slice(26, 46)
        props.setText(passwordGenerate)
        props.setTextError(false)

    }

    function passwordValidation(password) {

        if (password) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function textValidation(text) {

        if (text) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    return (

        <InputGroup>
            <InputGroup.Text className={props.textError == null ? 'inputTextIcon' : !props.textError ? 'inputTextIconSuccess' : 'inputTextIconError'}>
                {props.icon ? props.icon : <FontAwesomeIcon icon={faT} />}
            </InputGroup.Text>
            <Form.Control
                value={props.text}
                type={props.type === 'email' ? 'email' : props.type === 'phone' ? 'tel' : (props.type === 'password' && !passwordVisible) ? 'password' : 'text'}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                className={props.textError == null ? 'inputText' : !props.textError ? 'inputTextSuccess' : 'inputTextError'}
                onChange={(e) => { props.setText(e.target.value); onChange(e.target.value) }}
                onBlur={(e) => onBlur(e.target.value)}
                onKeyPress={(event) => props.onPressEnter && event.key === "Enter" ? props.onPressEnter() : null}
            />
            {props.type === 'password' && props.passwordButtonVisible ?
                <>
                    <InputGroup.Text
                        className={props.textError == null ? 'inputTextVisible' : !props.textError ? 'inputTextVisibleSuccess' : 'inputTextVisibleError'}
                        onClick={() => setPasswordVisible(!passwordVisible)}>
                        <FontAwesomeIcon icon={faEye} />
                    </InputGroup.Text>
                </>
                : null
            }
            {props.type === 'password' && props.passwordButtonGenerate ?
                <>
                    <InputGroup.Text
                        className={props.textError == null ? 'inputTextGenerate' : !props.textError ? 'inputTextGenerateSuccess' : 'inputTextGenerateError'}
                        onClick={() => getRandomPassword()}>
                        <FontAwesomeIcon icon={faShuffle} />
                    </InputGroup.Text>
                </>
                : null
            }
        </InputGroup>

    )

})

export default InputText
