import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import Select from 'react-select'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Input/InputSelect.css'

const InputSelect = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({ selectValidation: (values) => selectValidation(values) }))

    const [backgroundColor, setBackgroundColor] = useState()
    const [textColor, setTextColor] = useState()
    const [successBackgroundColor, setSuccessBackgroundColor] = useState()
    const [successTextColor, setSuccessTextColor] = useState()
    const [errorBackgroundColor, setErrorBackgroundColor] = useState()
    const [errorTextColor, setErrorTextColor] = useState()

    useEffect(() => {

        getColors()

    }, [])

    const selectStyle = {
        control: (baseStyles) => ({
            ...baseStyles,
            height: 40,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 0,
            textAlign: 'left',
            border: props.selectError == null ? '0 solid ' : !props.selectError ? '2px solid ' + successTextColor : '2px solid ' + errorTextColor,
            backgroundColor: props.selectError == null ? backgroundColor : !props.selectError ? successBackgroundColor : errorBackgroundColor,
            color: props.selectError == null ? textColor : !props.selectError ? successTextColor : errorTextColor,
            fontSize: 14,
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            textAlign: 'left',
            backgroundColor: backgroundColor,
            color: textColor,
            fontSize: 14,
        }),
        placeholder: (baseStyles) => {
            return {
                ...baseStyles,
                color: '#505356',
                fontSize: 14,
            }
        },

    }


    function selectValidation(values) {


        if (props.multiple) {
            if (values.length > 0) {
                props.setSelectError(false)
                return true
            } else {
                props.setSelectError(true)
                return false
            }
        } else {
            if (values != null && values.value.length > 0) {
                props.setSelectError(false)
                return true
            } else {
                props.setSelectError(true)
                return false
            }
        }


    }

    function getColors() {

        setBackgroundColor(getComputedStyle(document.body).getPropertyValue('--inputSelect'))
        setTextColor(getComputedStyle(document.body).getPropertyValue('--inputSelectText'))
        setSuccessBackgroundColor(getComputedStyle(document.body).getPropertyValue('--inputSelectSuccess'))
        setSuccessTextColor(getComputedStyle(document.body).getPropertyValue('--inputSelectSuccessText'))
        setErrorBackgroundColor(getComputedStyle(document.body).getPropertyValue('--inputSelectError'))
        setErrorTextColor(getComputedStyle(document.body).getPropertyValue('--inputSelectErrorText'))

    }

    return (

        <Row>
            <Col>
                <InputGroup.Text
                    className={props.selectError == null ? 'inputSelectIcon' : !props.selectError ? 'inputSelectIconSuccess' : 'inputSelectIconError'}>
                    {props.icon ? props.icon : <FontAwesomeIcon icon={faList} />}
                </InputGroup.Text>
                <Select
                    options={props.data}
                    value={props.select ? props.select : props.value}
                    placeholder={props.placeholder}
                    isMulti={props.multiple}
                    onChange={(value) => { props.setSelect(value); selectValidation(value) }}
                    className={'inputSelect'}
                    styles={selectStyle} />
            </Col>
        </Row>

    )

})

export default InputSelect
