const initialState = {
    id: null,
    firstname: null,
    email: null,
    tokenJWT: null
}

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'USER_SAVE':
            return {
                ...state,
                id: action.data.id,
                firstname: action.data.firstname,
                email: action.data.email,
                tokenJWT: action.data.tokenJWT
            }
        case 'USER_SIGNOUT':
            return {
                ...state,
                id: null,
                firstname: null,
                tokenJWT: null,
            }
        default:
            return state
    }

}

export default userReducer
