
import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { Fade } from 'react-awesome-reveal'
import '../../styles/Home/Us.css'

function Us() {

    return (

        <div id="nous" className='sectionLight'>
            <BrowserView>
                <Fade direction={'up'}>
                    <div className='sectionLightTitle'>
                        <FontAwesomeIcon icon={faUsers} className='sectionLightTitleIcon' />
                        Qui sommes-nous ?
                    </div>
                </Fade>
                <Fade duration={2000}>
                    <div>
                        <div className='usText'>
                            Bonjour et bienvenue sur le site de Mitsab,
                            <br />
                            Une entreprise fondée par deux passionnés d'informatique avec une vision novatrice.
                            Timothée et Bastien, tous deux ingénieurs en informatique formés à l'EPITA, sont ravis de vous accueillir dans leur monde technologique.
                            <br />
                            Notre mission : repousser les limites de l'innovation et fournir des solutions informatiques de pointe qui répondent aux défis de demain.
                            En tant qu'ingénieurs expérimentés, nous avons décidé de créer cette entreprise pour partager notre expertise, notre passion et notre engagement
                            envers l'excellence technologique.
                        </div>
                        <div className='usText'>
                            Notre expertise technique,
                            <br />
                            Forts d'une solide formation en ingénierie informatique à l'école EPITA et d'une expérience enrichissante dans le domaine, nous mettons à votre service
                            des compétences pointues en conception, développement et gestion de projets. Nous sommes fiers de notre capacité à fournir des solutions sur mesure qui
                            répondent aux besoins spécifiques de nos clients.
                        </div>
                        <div className='usText'>
                            Engagement qualité,
                            <br />
                            Chez Mitsab, la qualité est au cœur de tout ce que nous faisons. Nous nous engageons à fournir des produits et des services de la plus haute qualité, en
                            mettant l'accent sur l'efficacité, la fiabilité et la sécurité. Chaque projet est abordé avec une approche personnalisée, garantissant ainsi une
                            satisfaction client maximale.
                        </div>
                        <div className='usText'>
                            Innovation continue,
                            <br />
                            Le monde de la technologie évolue rapidement, et nous restons à la pointe de ces avancées. Nous investissons constamment dans la recherche et le développement
                            pour nous assurer que nos solutions intègrent les dernières technologies et offrent une valeur ajoutée à nos clients.
                        </div>
                        <div className='usText'>
                            Collaboration et partenariats,
                            <br />
                            Nous croyons fermement en la puissance de la collaboration. En travaillant étroitement avec nos clients, nous créons des partenariats solides qui favorisent le succès mutuel.
                            Notre approche transparente et notre communication ouverte garantissent une compréhension approfondie des besoins de nos clients et une réponse appropriée à leurs défis.
                        </div>
                        <div className='usText'>
                            Explorez notre site pour en savoir plus sur nos services et la manière dont nous pouvons vous accompagner dans la réalisation de vos objectifs informatiques. N'hésitez pas à nous contacter
                            pour discuter de vos projets et découvrir comment Mitsab peut être le partenaire idéal pour votre réussite technologique.
                        </div>
                        <div className='usText'>
                            Merci de faire partie de notre aventure !
                        </div>
                    </div>
                </Fade>
            </BrowserView>

            <MobileView>
                <Fade direction={'up'}>
                    <div className='sectionLightSmartphoneTitle'>
                        <FontAwesomeIcon icon={faUsers} className='sectionLightSmartphoneTitleIcon' />
                        Qui sommes-nous ?
                    </div>
                </Fade>
                <Fade duration={2000}>
                    <div>
                        <div className='usSmartphoneText'>
                            Bonjour et bienvenue sur le site de Mitsab,
                            <br />
                            Une entreprise fondée par deux passionnés d'informatique avec une vision novatrice.
                            Timothée et Bastien, tous deux ingénieurs en informatique formés à l'EPITA, sont ravis de vous accueillir dans leur monde technologique.
                            <br />
                            Notre mission : repousser les limites de l'innovation et fournir des solutions informatiques de pointe qui répondent aux défis de demain.
                            En tant qu'ingénieurs expérimentés, nous avons décidé de créer cette entreprise pour partager notre expertise, notre passion et notre engagement
                            envers l'excellence technologique.
                        </div>
                        <div className='usSmartphoneText'>
                            Notre expertise technique,
                            <br />
                            Forts d'une solide formation en ingénierie informatique à l'école EPITA et d'une expérience enrichissante dans le domaine, nous mettons à votre service
                            des compétences pointues en conception, développement et gestion de projets. Nous sommes fiers de notre capacité à fournir des solutions sur mesure qui
                            répondent aux besoins spécifiques de nos clients.
                        </div>
                        <div className='usSmartphoneText'>
                            Engagement qualité,
                            <br />
                            Chez Mitsab, la qualité est au cœur de tout ce que nous faisons. Nous nous engageons à fournir des produits et des services de la plus haute qualité, en
                            mettant l'accent sur l'efficacité, la fiabilité et la sécurité. Chaque projet est abordé avec une approche personnalisée, garantissant ainsi une
                            satisfaction client maximale.
                        </div>
                        <div className='usSmartphoneText'>
                            Innovation continue,
                            <br />
                            Le monde de la technologie évolue rapidement, et nous restons à la pointe de ces avancées. Nous investissons constamment dans la recherche et le développement
                            pour nous assurer que nos solutions intègrent les dernières technologies et offrent une valeur ajoutée à nos clients.
                        </div>
                        <div className='usSmartphoneText'>
                            Collaboration et partenariats,
                            <br />
                            Nous croyons fermement en la puissance de la collaboration. En travaillant étroitement avec nos clients, nous créons des partenariats solides qui favorisent le succès mutuel.
                            Notre approche transparente et notre communication ouverte garantissent une compréhension approfondie des besoins de nos clients et une réponse appropriée à leurs défis.
                        </div>
                        <div className='usSmartphoneText'>
                            Explorez notre site pour en savoir plus sur nos services et la manière dont nous pouvons vous accompagner dans la réalisation de vos objectifs informatiques. N'hésitez pas à nous contacter
                            pour discuter de vos projets et découvrir comment Mitsab peut être le partenaire idéal pour votre réussite technologique.
                        </div>
                        <div className='usSmartphoneText'>
                            Merci de faire partie de notre aventure !
                        </div>
                    </div>
                </Fade>
            </MobileView>
        </div>


    )

}

export default Us
