import { useState, useEffect } from 'react'
import { Fade, Zoom } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
// import Typist from 'react-typist'
import AdminUser from './AdminUser'
import AdminContact from './AdminContact'
import '../../styles/Admin/Admin.css'

function Admin() {

    const [users, setUsers] = useState([])
    const [usersDefault, setUsersDefault] = useState([])
    const [fullscreenDisplay, setFullscreenDisplay] = useState(null)

    const userStore = useSelector((state) => state.userReducer)

    useEffect(() => {

        window.scrollTo({ top: 600, behavior: 'instant' })

    }, [])

    return (
        <div id='admin'>
            <div id='adminTitle'>
                {/* <Typist>
                    {"Bonjour " + userStore.firstname + " "}
                </Typist> */}
            </div>

            {fullscreenDisplay == 'user' ?
                <Row>
                    <Col md="11">
                        <AdminUser
                            fullscreenDisplay={fullscreenDisplay} setFullscreenDisplay={setFullscreenDisplay}
                            users={users} setUsers={setUsers}
                            usersDefault={usersDefault} setUsersDefault={setUsersDefault}
                        />
                    </Col>
                    <Col md="1">
                    </Col>
                </Row>
                : null
            }
            {!fullscreenDisplay ?
                <>
                    <Row>
                        <Col>
                            <Fade direction={'left'} triggerOnce={true}>
                                <AdminUser
                                    fullscreenDisplay={fullscreenDisplay} setFullscreenDisplay={setFullscreenDisplay}
                                    users={users} setUsers={setUsers}
                                    usersDefault={usersDefault} setUsersDefault={setUsersDefault}
                                />
                            </Fade>
                        </Col>
                        <Col>
                            <Fade direction={'right'} triggerOnce={true}>
                                <AdminContact />
                            </Fade>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Fade right>
                                {/* <AdminProject users={users} /> */}
                            </Fade>
                        </Col>
                        <Col>
                            <Fade right delay={300}>
                                {/* <AdminStatistic
                    contacts={contacts}
                    projects={projects}
                /> */}
                            </Fade>
                        </Col>
                    </Row>
                </>
                : null
            }


        </div>
    )

}

export default Admin
