import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import imageHomeAutomation1 from '../../../assets/images/offer/offerHome1.jpg'
import imageHomeAutomation2 from '../../../assets/images/offer/offerHome2.jpg'
import imageHomeAutomation3 from '../../../assets/images/offer/offerHome3.webp'
import iconHomeAssistant from '../../../assets/icons/techno/homeAssistant.png'
import iconPhilipsHue from '../../../assets/icons/techno/philipsHue.png'
import iconRaspberry from '../../../assets/icons/techno/raspberry.png'
import iconDocker from '../../../assets/icons/techno/docker.png'
import iconLinux from '../../../assets/icons/techno/linux.png'
import iconWindows from '../../../assets/icons/techno/windows.png'
import '../../../styles/Home/Offer/HomeAutomation.css'

function HomeAutomation() {

    return (

        <div id="homeAutomation" className='sectionLight'>
            <ParallaxProvider>

                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faHouseChimney} className='sectionLightTitleIcon' />
                            Domotique
                        </div>
                    </Fade>
                    <div className='homeAutomationSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='homeAutomationSectionLightText'>
                                        <div className='homeAutomationTextParagraph'>
                                            Besoin de faire évoluer votre maison ou votre espace de travail de façon intelligente, de piloter vos objets connectés
                                            depuis votre Home Assistant entièrement personnalisé pour vous ?
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHomeAutomation1} width={'70%'} className='smartphoneImage' alt="imageHomeAutomation1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='homeAutomationSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageHomeAutomation2} width={'50%'} className='smartphoneImage' alt="imageHomeAutomation2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='homeAutomationSectionDarkText'>
                                            <div className='homeAutomationTextParagraph'>
                                                Optimisez la consommation d'énergie de votre maison en contrôlant l'éclairage et vos prises électriques.
                                            </div>
                                            <div className='homeAutomationTextParagraph'>
                                                Accéder simplement au suivi de votre consommation ou à l'état de vos objets connectés depuis votre smartphone ou ordinateur.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='homeAutomationSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='homeAutomationSectionLightText'>
                                        <div className='homeAutomationTextParagraph'>
                                            Nous restons à la pointe de la technologie en intégrant les dernières avancées en matière de domotique pour vous
                                            offrir une expérience exceptionnelle.
                                        </div>
                                        <div className='homeAutomationTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour planifier une consultation gratuite et découvrir comment la domotique peut
                                            améliorer votre vie quotidienne.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHomeAutomation3} width={'50%'} className='smartphoneImage' alt="imageHomeAutomation3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='homeAutomationIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconHomeAssistant} width="60px" alt="iconHomeAssistant" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconPhilipsHue} width="60px" alt="iconPhilipsHue" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconRaspberry} width="60px" alt="iconRaspberry" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='homeAutomationIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faHouseChimney} className='sectionLightSmartphoneTitleIcon' />
                            Domotique
                        </div>
                    </Fade>
                    <div className='homeAutomationSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='homeAutomationSmartphoneSectionLightText'>
                                        <div className='homeAutomationTextParagraph'>
                                            Besoin de faire évoluer votre maison ou votre espace de travail de façon intelligente, de piloter vos objets connectés
                                            depuis votre Home Assistant entièrement personnalisé pour vous ?
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHomeAutomation1} width={'90%'} className='homeAutomationSmartphoneImage' alt="imageHomeAutomation1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='homeAutomationSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageHomeAutomation2} width={'90%'} className='homeAutomationSmartphoneImage' alt="imageHomeAutomation2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='homeAutomationSmartphoneSectionDarkText'>
                                            <div className='homeAutomationTextParagraph'>
                                                Optimisez la consommation d'énergie de votre maison en contrôlant l'éclairage et vos prises électriques.
                                            </div>
                                            <div className='homeAutomationTextParagraph'>
                                                Accéder simplement au suivi de votre consommation ou à l'état de vos objets connectés depuis votre smartphone ou ordinateur.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='homeAutomationSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='homeAutomationSmartphoneSectionLightText'>
                                        <div className='homeAutomationTextParagraph'>
                                            Nous restons à la pointe de la technologie en intégrant les dernières avancées en matière de domotique pour vous
                                            offrir une expérience exceptionnelle.
                                        </div>
                                        <div className='homeAutomationTextParagraph'>
                                            Contactez-nous dès aujourd'hui pour planifier une consultation gratuite et découvrir comment la domotique peut
                                            améliorer votre vie quotidienne.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageHomeAutomation3} width={'90%'} className='homeAutomationSmartphoneImage' alt="imageHomeAutomation3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='homeAutomationIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconHomeAssistant} width="60px" alt="iconHomeAssistant" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconPhilipsHue} width="60px" alt="iconPhilipsHue" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='homeAutomationIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconRaspberry} width="60px" alt="iconRaspberry" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='homeAutomationIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconLinux} width="50px" alt="iconLinux" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconWindows} width="55px" alt="iconWindows" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default HomeAutomation
