import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import imageSmartphone1 from '../../../assets/images/offer/offerApp1.png'
import imageSmartphone2 from '../../../assets/images/offer/offerApp2.jpg'
import imageSmartphone3 from '../../../assets/images/offer/offerApp3.jpg'
import iconJs from '../../../assets/icons/techno/js.png'
import iconMySql from '../../../assets/icons/techno/mysql.png'
import iconNodeJS from '../../../assets/icons/techno/nodejs.png'
import iconReactNative from '../../../assets/icons/techno/reactnative.png'
import iconNestJS from '../../../assets/icons/techno/nestjs.png'
import iconDocker from '../../../assets/icons/techno/docker.png'
import '../../../styles/Home/Offer/Smartphone.css'

function Smartphone() {

    return (

        <div id="smartphone" className='sectionLight'>
            <ParallaxProvider>

                <BrowserView>
                    <Fade direction={'up'}>
                        <div className='sectionLightTitle'>
                            <FontAwesomeIcon icon={faMobileScreen} className='sectionLightTitleIcon' />
                            Application Mobile
                        </div>
                    </Fade>
                    <div className='smartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='smartphoneSectionLightText'>
                                        <div className='smartphoneTextParagraph'>
                                            Un site web ne vous suffit pas ? Vous avez décidé d'avoir votre propre application mobile sur mesure qui répond à
                                            vos besoins spécifiques et vous permet de toucher votre public cible de manière plus efficace.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Nous commencerons par une consultation approfondie pour comprendre vos objectifs commerciaux, les
                                            fonctionnalités souhaitées et les plateformes ciblées (iOS, Android, ...).
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageSmartphone1} width={'40%'} className='smartphoneImage' alt="imageSmartphone1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div id='websiteSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageSmartphone2} width={'70%'} className='smartphoneImage' alt="imageSmartphone2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'} delay={300}>
                                        <div id='smartphoneSectionDarkText'>
                                            <div className='smartphoneTextParagraph'>
                                                Notre équipe de concepteurs d'interface utilisateur (UI) et d'expérience utilisateur (UX) créera une interface
                                                intuitive, garantissant une expérience exceptionnelle.
                                            </div>
                                            <div className='smartphoneTextParagraph'>
                                                Notre équipe de développement mobile est experte dans la technologie ReactNative, framework permettant une
                                                réalisation rapide, optimisée et sécurisée pour des performances et une compatibilité multiplateforme (IOS et Android).
                                            </div>
                                            <div className='smartphoneTextParagraph'>
                                                Après la finalisation du développement et l'accord du client, nous nous occuperons des certificats et du processus de
                                                publication aux différents stores d'applications (App Store, Google Play) et assurerons le suivi des commentaires et des mises à jour.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='smartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='smartphoneSectionLightText'>
                                        <div className='smartphoneTextParagraph'>
                                            Une fois le projet terminé, nous fournirons une formation à votre équipe pour gérer l'application et offrirons un
                                            support continu pour garantir que votre application reste performante.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Si vous êtes prêt à concrétiser votre idée d'application mobile, n'hésitez pas à nous contacter pour discuter de votre projet.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Nous serions ravis de collaborer avec vous pour développer une application qui répond à vos besoins et dépasse vos attentes.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageSmartphone3} width={'60%'} className='smartphoneImage' alt="imageSmartphone3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='smartphoneIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconJs} width="55px" alt="iconJs" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='smartphoneIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconReactNative} width="100px" alt="iconReactNative" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconNestJS} width="120px" alt="iconNestJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconDocker} width="130px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <Fade direction={'up'}>
                        <div className='sectionLightSmartphoneTitle'>
                            <FontAwesomeIcon icon={faMobileScreen} className='sectionLightSmartphoneTitleIcon' />
                            Application Mobile
                        </div>
                    </Fade>
                    <div className='smartphoneSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='smartphoneSmartphoneSectionLightText'>
                                        <div className='smartphoneTextParagraph'>
                                            Un site web ne vous suffit pas ? Vous avez décidé d'avoir votre propre application mobile sur mesure qui répond à
                                            vos besoins spécifiques et vous permet de toucher votre public cible de manière plus efficace.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Nous commencerons par une consultation approfondie pour comprendre vos objectifs commerciaux, les
                                            fonctionnalités souhaitées et les plateformes ciblées (iOS, Android, ...).
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageSmartphone1} width={'90%'} className='smartphoneImageSmartphone' alt="imageSmartphone1" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <Parallax speed={-30}>
                        <div className='smartphoneSmartphoneSectionDark'>
                            <Row>
                                <Col md="6">
                                    <Fade direction={'left'}>
                                        <img src={imageSmartphone2} width={'90%'} className='smartphoneImageSmartphone' alt="imageSmartphone2" />
                                    </Fade>
                                </Col>
                                <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Fade direction={'left'}>
                                        <div className='smartphoneSmartphoneSectionDarkText'>
                                            <div className='smartphoneTextParagraph'>
                                                Notre équipe de concepteurs d'interface utilisateur (UI) et d'expérience utilisateur (UX) créera une interface
                                                intuitive, garantissant une expérience exceptionnelle.
                                            </div>
                                            <div className='smartphoneTextParagraph'>
                                                Notre équipe de développement mobile est experte dans la technologie ReactNative, framework permettant une
                                                réalisation rapide, optimisée et sécurisée pour des performances et une compatibilité multiplateforme (IOS et Android).
                                            </div>
                                            <div className='smartphoneTextParagraph'>
                                                Après la finalisation du développement et l'accord du client, nous nous occuperons des certificats et du processus de
                                                publication aux différents stores d'applications (App Store, Google Play) et assurerons le suivi des commentaires et des mises à jour.
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                    <div className='smartphoneSmartphoneSectionLight'>
                        <Row>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <Fade direction={'right'}>
                                    <div className='smartphoneSmartphoneSectionLightText'>
                                        <div className='smartphoneTextParagraph'>
                                            Une fois le projet terminé, nous fournirons une formation à votre équipe pour gérer l'application et offrirons un
                                            support continu pour garantir que votre application reste performante.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Si vous êtes prêt à concrétiser votre idée d'application mobile, n'hésitez pas à nous contacter pour discuter de votre projet.
                                        </div>
                                        <div className='smartphoneTextParagraph'>
                                            Nous serions ravis de collaborer avec vous pour développer une application qui répond à vos besoins et dépasse vos attentes.
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md="6">
                                <Fade direction={'right'}>
                                    <img src={imageSmartphone3} width={'90%'} className='smartphoneImageSmartphone' alt="imageSmartphone3" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='smartphoneIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconJs} width="55px" alt="iconJs" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconMySql} width="100px" alt="iconMySql" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='smartphoneIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'right'}>
                                    <img src={iconNodeJS} width="80px" alt="iconNodeJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'down'}>
                                    <img src={iconReactNative} width="100px" alt="iconReactNative" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                    <div className='smartphoneIcons'>
                        <Row>
                            <Col>
                                <Fade direction={'left'}>
                                    <img src={iconNestJS} width="100px" alt="iconNestJS" />
                                </Fade>
                            </Col>
                            <Col>
                                <Fade direction={'up'}>
                                    <img src={iconDocker} width="110px" alt="iconDocker" />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </MobileView>

            </ParallaxProvider>
        </div>

    )

}

export default Smartphone
