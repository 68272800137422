import { useEffect, useState } from 'react'
import axios from 'axios'
import { Zoom } from 'react-awesome-reveal'
import { RotatingLines } from 'react-loader-spinner'
import { Tab, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircle, faEnvelope, faLocation, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import InputSearch from '../Input/InputSearch'
import '../../styles/Admin/AdminContact.css'
import '../../styles/utils/Tabs.css'

function AdminContact() {

    const [isLoading, setIsLoading] = useState(true)
    const [contacts, setContacts] = useState([])
    const [contactsDefault, setContactsDefault] = useState([])

    useEffect(() => {

        getContacts()

    }, [])

    function getContacts() {

        axios.get(window.location.protocol + '//api.' + window.location.host + '/' + "contact").then((response) => {
            if (response.data.length > 0) {
                var contactViewCounter = 0
                var contactView = []
                var contactNotView = []
                response.data.map((item, index) => {
                    item.createdAt = new Date(item.createdAt).toLocaleString('fr-FR')
                    item.updatedAt = new Date(item.updatedAt).toLocaleString('fr-FR')
                    if (!item.view) {
                        contactViewCounter++
                        contactNotView.push(item)
                    } else {
                        contactView.push(item)
                    }
                    return item
                })
                response.data = contactNotView.concat(contactView)
                const contactHome = { message: <><br /><br />{"Vous avez " + contactViewCounter + " messages non lus"}</> }
                response.data.unshift(contactHome)
                setContacts(response.data)
                setContactsDefault(response.data)
            }
            setIsLoading(false)
        }).catch((error) => {
            console.log("error :\n" + JSON.stringify(error) + "\n\n")
            setIsLoading(false)
        })

    }

    function viewContact(contactId) {

        axios.put(window.location.protocol + '//api.' + window.location.host + '/' + "contact/" + contactId).then(() => { })

    }

    return (

        <div id='adminContactContainer'>
            <div id='adminContactTitle'>
                <FontAwesomeIcon icon={faEnvelope} id='adminContactTitleIcon' />
                Messages
            </div>
            <div id='adminContact'>
                <InputSearch
                    type='contact'
                    dataDefault={contactsDefault} setData={setContacts}
                    placeholder={"Rechercher"}
                />
                {isLoading ?
                    <RotatingLines
                        strokeColor="#e2e9f0"
                        strokeWidth="5"
                        animationDuration="1"
                        width="40"
                        visible={true}
                    />
                    :
                    <Zoom>
                        <Tab.Container defaultActiveKey={0}>
                            <div className='tabHorizontal'>
                                <div className='tabHorizontalLink'>
                                    <Nav variant="pills" className="flex-column">
                                        {contacts.map((item, index) => {
                                            return (index !== 0 ?
                                                <Nav.Item key={index} onClick={() => viewContact(item.id)}>
                                                    <Nav.Link eventKey={index}>
                                                        <div className='adminContactName'>
                                                            {item.view ?
                                                                <FontAwesomeIcon icon={faCircle} className={'adminContactView'} />
                                                                :
                                                                <FontAwesomeIcon icon={faCircle} className={'adminContactNotView'} />
                                                            }

                                                            {item.firstname + " " + item.lastname}
                                                        </div>
                                                        <div className='adminContactDate'>
                                                            {item.createdAt.slice(0, -9)}
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                : null
                                            )
                                        })}
                                    </Nav>
                                </div>
                                <div className='tabHorizontalBody'>
                                    <Tab.Content>
                                        {contacts.map((item, index) => {
                                            return (
                                                <Tab.Pane key={index} eventKey={index}>
                                                    <div className='contactMessage'>
                                                        {item.message}
                                                    </div>
                                                    <br /><br />
                                                    {index !== 0 ?
                                                        <>
                                                            __________________
                                                            <div className="contactMessageInfo">
                                                                <FontAwesomeIcon icon={faUser} className='contactMessageInfoIcon' />
                                                                {item.firstname + " " + item.lastname}
                                                                <br />
                                                                <FontAwesomeIcon icon={faEnvelope} className='contactMessageInfoIcon' />
                                                                {item.email}
                                                                <br />
                                                                <FontAwesomeIcon icon={faPhone} className='contactMessageInfoIcon' />
                                                                {item.phone}
                                                                <br />
                                                                <FontAwesomeIcon icon={faLocation} className='contactMessageInfoIcon' />
                                                                {item.ip}
                                                                <br />
                                                                <FontAwesomeIcon icon={faCalendar} className='contactMessageInfoIcon' />
                                                                {item.createdAt}
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                </Tab.Pane>
                                            )
                                        })}
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Zoom>
                }
            </div>
        </div>

    )

}

export default AdminContact
