import { useRef, useState } from 'react'
import axios from 'axios'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPlus } from '@fortawesome/free-solid-svg-icons'
import Modal from '../utils/Modal'
import InputText from '../Input/InputText'
import Button from '../utils/Button'
import '../../styles/Admin/AdminUserCompanyAdd.css'

function AdminUserCompanyAdd({ getCompanies }) {

    const [isLoading, setIsLoading] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const nameRef = useRef()
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
    // const userRef = useRef()
    // const [user, setUser] = useState(null)
    // const [userData, setUserData] = useState(null)
    // const [userError, setUserError] = useState(null)

    // const selectTypeData = [
    //     {
    //         value: 'person',
    //         label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Particulier</>
    //     }, {
    //         value: 'company',
    //         label: <><FontAwesomeIcon icon={faBuilding} className='inputSelectLabelIcon' />Entreprise</>
    //     }
    // ]

    // const selectRoleData = [
    //     {
    //         value: 'user',
    //         label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />Utilisateur</>
    //     }, {
    //         value: 'admin',
    //         label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />Administrateur</>
    //     }
    // ]

    // useEffect(() => {

    //     formatUsers()

    // }, [])

    // function formatUsers() {

    //     const formatUsers = []
    //     users.map((item) => {
    //         var userItem = null
    //         if (item.role == 'user') {
    //             userItem = {
    //                 value: item.id,
    //                 label: <><FontAwesomeIcon icon={faUser} className='inputSelectLabelIcon' />{item.firstname + " " + item.lastname}</>
    //             }
    //         } else {
    //             userItem = {
    //                 value: item.id,
    //                 label: <><FontAwesomeIcon icon={faUserSecret} className='inputSelectLabelIcon' />{item.firstname + " " + item.lastname}</>
    //             }
    //         }
    //         formatUsers.push(userItem)
    //     })
    //     setUserData(formatUsers)

    // }

    function addCompany() {

        setIsLoading(true)
        var error = []
        if (!nameRef.current.nameValidation(name)) {
            error.push("Le nom est incorrect")
        }
        if (error.length > 0) {
            error.map((item) => toast.error(item))
            setIsLoading(false)
        } else {
            const toastId = toast.loading("Ajout...")
            axios.post(window.location.protocol + '//api.' + window.location.host + '/' + "user/company", {
                name: name,
            }).then((response) => {
                if (response.data === 'USERCOMPANY_CREATED') {
                    setName("")
                    getCompanies()
                    setDisplayModal(false)
                    toast.update(toastId, { render: "Entreprise ajouté", type: "success", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            }).catch((error) => {
                if (error.response) {
                    console.log("error :\n" + JSON.stringify(error.response.data.message) + "\n\n")
                    var errorMessage = error.response.data.message
                    if (errorMessage === 'USERCOMPANY_ALREADY_EXIST') {
                        errorMessage = "L'entreprise existe déjà"
                    } else if (errorMessage === 'ERROR_USERCOMPANY_CREATION') {
                        errorMessage = "Erreur lors de l'ajout de l'entreprise"
                    }
                    toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
                }
                setIsLoading(false)
            })
        }

    }

    return (

        <div id="adminUserCompanyAdd">
            <Modal
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                buttonDisplayType={'secondary'}
                buttonDisplayIcon={<FontAwesomeIcon icon={faPlus} />}
                buttonDisplayText={"Entreprise"}
                header={"Ajouter une entreprise"}
                body={
                    <div id="adminUserCompanyAddForm">
                        <InputText
                            type={'name'}
                            ref={nameRef}
                            text={name} setText={setName}
                            textError={nameError} setTextError={setNameError}
                            icon={<FontAwesomeIcon icon={faBuilding} />}
                            placeholder={"Nom"}
                            maxLength={32}
                        />
                        {/* <InputSelect
                            ref={userRef}
                            data={userData}
                            select={user} setSelect={setUser}
                            selectError={userError} setSelectError={setUserError}
                            icon={<FontAwesomeIcon icon={faGear} />}
                            value={user}
                            placeholder={"Utilisateur"}
                            multiple={false}
                        /> */}
                    </div>
                }
                footer={
                    <>
                        {isLoading ?
                            <RotatingLines
                                strokeColor="#e2e9f0"
                                strokeWidth="5"
                                animationDuration="1"
                                width="40"
                                visible={true}
                            />
                            :
                            <Button
                                type={'success'}
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                text={'Ajouter'}
                                onClick={() => addCompany()}
                            />
                        }
                    </>
                }
            />
        </div>

    )

}

export default AdminUserCompanyAdd
